import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import Home from './user/pages/Home';
import About from './user/pages/About';
import Category from './user/pages/Category';

import Blog from './user/pages/Blog';
import AllBlogs from './user/pages/AllBlogs';
import Contact from './user/pages/Contact';
import UserLogin from './user/pages/UserLogin';
import UserSignup from './user/pages/UserSignup';
import MyBlogs from './user/pages/MyBlogs';
import CreateBlogs from './user/pages/CreateBlogs';
import Product from './user/pages/Product';
import Checkout from './user/pages/Checkout';
import AccountOrder from './user/pages/AccountOrder';
import Accountprofile from './user/pages/Accountprofile';
import AccountPassword from './user/pages/AccountPassword';
import Account from './user/pages/Account';
import PrivateStore from './user/pages/PrivateStore';
import ProductCollection from './user/pages/ProductCollection';
import PrivateProduct from './user/pages/PrivateProduct';
import Success from './user/pages/Success';
import Cancel from './user/pages/Cancel';
import AccountOrderView from './user/pages/AccountOrderView';
import Page from './user/pages/Page';
import { Toaster } from "react-hot-toast";
import { BlogProvider } from './fetchdata/BlogContext';

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};


function App() {

  const location = useLocation(); // Get the current location from React Router
  const isLoginFromLocalStorage = localStorage.getItem("token") ? true : false;
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(isLoginFromLocalStorage);

  useEffect(() => {
    setIsUserAuthenticated(isLoginFromLocalStorage);
  }, [isLoginFromLocalStorage, location.pathname]);



  return (

    <>
      <BlogProvider>
        <Toaster />

        <Routes>

          {/* user routes */}

          {/* <Route path="/" element={<AllBlogs />} /> */}
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/allblogs" element={<AllBlogs />} />
          <Route path="/myblogs" element={<MyBlogs />} />
          <Route path="/login" element={<UserLogin updateAuthStatus={setIsUserAuthenticated} />} />
          <Route path="/signup" element={<UserSignup />} />
          <Route path="/add-blog" element={<CreateBlogs />} />
          <Route path="/blog/:slug" element={<Blog />} />

          <Route path="/category/:id" element={<Category />} />
          <Route path="/collection/:id" element={<PrivateStore />} />
          <Route path="/collection/:storeid/:id" element={<ProductCollection />} />



          <Route path="/product/:id" element={<Product />} />
          <Route path="/private-product/:id" element={<PrivateProduct />} />


          <Route path="/checkout" element={<Checkout updateAuthStatus={setIsUserAuthenticated} />} />
          <Route path="/account" element={<Account />} />

          <Route path="/account/orders" element={<AccountOrder />} />
          <Route path="/account/profile" element={<Accountprofile />} />
          <Route path="/account/password" element={<AccountPassword />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />

          <Route path="/account/order/:orderId" element={isUserAuthenticated ? <AccountOrderView /> : <Navigate to="/login" />} />


          <Route path="/" element={<Home />} />

          <Route path="page/:slug" element={<Page />} />

        </Routes>
        <ScrollToTop />
      </BlogProvider>


    </>
  );
}

export default App;