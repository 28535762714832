import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { authActions } from "../../redux/store";
import toast from "react-hot-toast";
import TokenValidator from "../../fetchdata/TokenValidator";
import Cart from "./Cart";
import { useCart } from "react-use-cart";
import $ from "jquery";
import { useBlogContext } from "../../fetchdata/BlogContext";


const MenuItem = ({ item }) => {
    return (
        <li key={item.id} className={item.children ? 'has_submenu' : ''}>
            {item.children && item.children.length > 0 ? (
                <>
                    <span>
                        <Link to={item.link} target={item.target === '_blank' ? '_blank' : ''}>
                            {item.text}
                        </Link>
                        <i class="ri-arrow-right-s-line"></i>
                    </span>
                    <SubMenu items={item.children} />
                </>
            ) : (
                <Link to={item.link} target={item.target === '_blank' ? '_blank' : ''}>
                    {item.text}
                </Link>
            )}
        </li>
    );
};

const SubMenu = ({ items }) => {



    return (
        <ul className="submenu">
            <li>
                <span className="mobile_menu_back">
                    <i class="ri-arrow-left-line"></i> Back
                </span>
            </li>
            {items.map(child => (
                <MenuItem key={child.id} item={child} />
            ))}
        </ul>
    );
};


const Header = () => {

    const {
        isEmpty,
        totalItems,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
    } = useCart();

    const [vatActive, setVatActive] = useState(
        localStorage.getItem('vatActive') === 'true'
    );

    useEffect(() => {
        localStorage.setItem('vatActive', vatActive);
    }, [vatActive]);


    const handleToggle = () => {
        setVatActive(!vatActive);
        window.location.reload();

    };

    const { totalUniqueItems } = useCart();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    require('../assets/css/style.css')



    useEffect(() => {
        // Dynamically create a <link> element to load the CSS file
        const linkElement = document.createElement("link");
        linkElement.rel = "stylesheet";
        linkElement.href = "../assets/css/style.css"; // Adjust the path as needed
        document.head.appendChild(linkElement);
    }, []);



    // const isLogin = useSelector(state => state.isLogin)
    // console.log(isLogin)
    const { Headers, isHeader, AllCategoriess, AllProducts } = useBlogContext();
    if (isHeader) {
        console.log("Headers", Headers);
        console.log('AllProductsAllProducts', AllProducts)
    }


    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const toggleSubMenu = (event) => {
        const submenu = event.target.nextElementSibling;
        submenu.classList.toggle("open");
    };


    const dispatch = useDispatch();
    const navigate = useNavigate();
    //state
    const [value, setValue] = useState();

    const isLoginFromLocalStorage = localStorage.getItem('token') ? true : false;
    const [isLogin, setIsLogin] = useState(isLoginFromLocalStorage);

    useEffect(() => {
        setIsLogin(isLoginFromLocalStorage);
    }, [isLoginFromLocalStorage]);

    //logout
    const handleLogout = () => {
        try {
            dispatch(authActions.logout());
            toast.success("Logout Successfully");
            navigate("/login");
            // localStorage.clear();
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('user');


        } catch (error) {
            console.log(error);
        }
    };
    const [isCartActive, setCartIsActive] = useState(false);

    const toggleCartClass = () => {
        setCartIsActive(!isCartActive);
    };


    const [searchQuery, setSearchQuery] = useState("");

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleClearClick = () => {
        setSearchQuery("");
    };

    const filteredProducts = AllProducts.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const filteredCategories = AllCategoriess.filter(category =>
        category.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        window.scrollTo(0, 0);

        $("#mobile_menu_trigger").click(function () {
            $("#mobile_menu_container").toggleClass("open");
        });
        $("#mobile_menu_mob_trigger").click(function () {
            $("#mobile_menu_container").toggleClass("open");
        });

        $("#mobile_menu_trigger_mob").click(function () {
            $("#mobile_menu_container").toggleClass("open");
        });



        $("#mobile_menu_container, .mobile_menu_close, .mobile_menu_close i").on(
            "click",
            function (e) {
                if (e.target === this) {
                    $("#mobile_menu_container").toggleClass("open");
                    /* $('#mobile_menu_container_inner').toggleClass('open'); */
                }
            }
        );

        $(document).on("click", "#mobile_menu .has_submenu > span", function () {
            $("+ .submenu", $(this)).addClass("open");
        });

        $(document).on("click", "#mobile_menu .sub_sub_menu > span", function () {
            $("+ ul", $(this)).addClass("open");
        });

        $(document).on("click", "#mobile_menu .mobile_menu_back", function () {
            $(this).closest("ul").removeClass("open");
        });
    }, []);


    return (
        <>
            <TokenValidator />

            <div className="p-0 tophead " style={{ overflow: 'hidden' }}>

                <div className="row pinkhead" >
                    <div className="col-md-8 d-sm-none">
                        <div className="d-flex gap-2 p-2">
                            <Link to="/" className="bg-white px-1 rounded  text-decoration-none">
                                <i className="ri-twitter-x-line"></i>
                            </Link>
                            <Link to="/" className="bg-white px-1 rounded  text-decoration-none">
                                <i className="ri-instagram-line"></i>
                            </Link>
                            <Link to="/" className="bg-white px-1 rounded  text-decoration-none">
                                <i className="ri-youtube-line"></i>
                            </Link>
                            <Link to="/" className="bg-white px-1 rounded  text-decoration-none">
                                <i class="ri-facebook-fill"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-4 p-0" >
                        <div className="d-flex  text-white align-items-center justify-content-center" style={{ overflow: 'hidden' }}>
                            <Link to="/" className="text-white gap-1 d-flex text-decoration-none px-3 py-2 border-white-right fw-light">
                                <i className="ri-heart-line text-white fw-light"></i> WishList
                            </Link>
                            <Link to="/" className="text-white gap-1 d-flex text-decoration-none  px-3 py-2 border-white-right fw-light">
                                <i className="ri-map-pin-2-fill text-white fw-light"></i> Visit <span className="d-sm-none"> Our Showroom  </span>
                            </Link>

                            <Link to="/" className="text-white gap-1 d-flex text-decoration-none  px-3 py-2 border-white-right me-2 fw-light">
                                <b className="fw-bold"> £  </b>{' '} GBP
                            </Link>

                            <div className="form-check form-switch  gap-2 align-items-center d-flex">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked={vatActive}
                                    onChange={handleToggle}
                                />
                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked fw-light">
                                    {vatActive ? 'VAT' : 'VAT'}

                                </label>
                            </div>

                        </div>
                    </div>
                </div>


            </div>




            <nav class="navbar overflow-sm-hidden">
                <div class="container-fluid">
                    <div className="logo my-2 d-sm-flex align-items-center gap-4 overflow-hidden"  >
                        <Link to="/" style={{ aspectRatio: '300/50', height: 50 }} >
                            {Headers.meta_logo && Headers.meta_logo !== undefined ? (
                                <img src={Headers.meta_logo} width={100} alt="logo" style={{
                                    maxHeight: 50, width: "auto", maxWidth: '100%'
                                }}
                                />
                            ) : (
                                <div
                                    className="card-1 skeleton"
                                    style={{ borderRadius: 5, aspectRatio: '300/50', height: 50 }}
                                ></div>
                            )}
                        </Link>

                        <div className="d-desk-none" onClick={toggleMenu} id="mobile_menu_mob_trigger" >


                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 32 32">
                                <g data-name="Layer 5">
                                    <rect width={10} height={10} x={5} y={5} rx={3} />
                                    <rect width={10} height={10} x={17} y={5} rx={3} />
                                    <rect width={10} height={10} x={5} y={17} rx={3} />
                                    <rect width={10} height={10} x={17} y={17} rx={3} />
                                </g>
                            </svg>

                        </div>


                    </div>

                    <div className="new-design search  me-4 ">
                        <input placeholder="Search for a product or brand" type="text" className="d-block" value={searchQuery}
                            onChange={handleInputChange} />
                        <button type="submit" className="search-icon "><i class="bi bi-search"></i></button>

                        {searchQuery && (<>
                            <ul class="searchfloat">


                                {searchQuery && filteredProducts && filteredProducts.map(item => (

                                    <li key={item.id}>
                                        <Link to={`/product/${item._id}`} onClick={handleClearClick} >{item.title}</Link>
                                    </li>

                                ))}

                                {searchQuery && filteredCategories && filteredCategories.map(item => (

                                    <li key={item.id}>
                                        <Link to={`/category/${item._id}`} onClick={handleClearClick} >{item.title}</Link>
                                    </li>

                                ))}

                            </ul>

                        </>)}
                    </div>

                    <div className="star mt-2">
                        <img src="/assets/images/google-star.svg" alt="" />
                    </div>

                    <div className="d-md-flex my-new-cart gap-3 d-sm-none">
                        <Link to={'tel:01214725300'} className="d-flex gap-3 text-dark text-decoration-none align-items-center">
                            <img src="/assets/images/call.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">Need Help?</h6>

                                <p className="mb-0" >0121 472 5300</p>
                            </div>
                        </Link>

                        {!isLogin ? (
                            <Link to={'/login'} className="d-flex gap-3 text-dark text-decoration-none align-items-center">
                                <img src="/assets/images/user.svg" alt="" width={30} />
                                <div className="d-block">
                                    <h6 className="mb-0 fw-bold">My Account</h6>
                                    <p className="mb-0" >Signup | Login</p>
                                </div>
                            </Link>
                        ) : (
                            <Link to={'/account'} className="d-flex gap-3 text-dark text-decoration-none align-items-center">
                                <img src="/assets/images/user.svg" alt="" width={30} />
                                <div className="d-block">
                                    <h6 className="mb-0 fw-bold">My Account</h6>
                                    <p className="mb-0 " >Dashboard</p>
                                </div>
                            </Link>

                        )}
                        <Link onClick={toggleCartClass} className={`d-flex gap-3 text-dark text-decoration-none align-items-center ${isCartActive ? 'cartbutton Isactive' : 'cartbutton'}`}>
                            <img src="/assets/images/bag.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">Basket</h6>
                                <p className="mb-0" >£{cartTotal}</p>
                            </div>
                        </Link>

                        <button className={isCartActive ? 'cartbutton Isactive' : 'cartbutton'} onClick={toggleCartClass} >
                            {isCartActive && <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={25}
                                height={25}
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#1d1d1d"
                                strokeWidth={2}
                                strokeLinecap="square"
                                strokeLinejoin="arcs"
                            >
                                <line x1={18} y1={6} x2={6} y2={18} />
                                <line x1={6} y1={6} x2={18} y2={18} />
                            </svg>

                            }
                        </button>

                    </div>


                    <div class="mycart-menu-box ">


                        <ul className="link-list2 ms-2 d-lg-none gap-2 align-items-center justify-content-between ">

                            <li>
                                <button className={isCartActive ? 'cartbutton Isactive' : 'cartbutton'} onClick={toggleCartClass} >
                                    {isCartActive ? <>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={25}
                                            height={25}
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#1d1d1d"
                                            strokeWidth={2}
                                            strokeLinecap="square"
                                            strokeLinejoin="arcs"
                                        >
                                            <line x1={18} y1={6} x2={6} y2={18} />
                                            <line x1={6} y1={6} x2={18} y2={18} />
                                        </svg>

                                    </>
                                        :
                                        <Link onClick={toggleCartClass} className={`d-flex gap-2 text-dark text-decoration-none align-items-end ${isCartActive ? 'cartbutton Isactive' : 'cartbutton'}`}>
                                            <img src="/assets/images/bag.svg" alt="" width={30} />
                                            <div className="d-block text-start">
                                                <h6 class="mb-0 fw-bold">Basket</h6>
                                                <p className="mb-0" >£{cartTotal}</p>
                                            </div>
                                        </Link>}
                                </button>
                            </li>

                            {/* <li className="d-desk-none" onClick={toggleMenu} id="mobile_menu_mob_trigger" >


                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32">
                                    <g data-name="Layer 5">
                                        <rect width={10} height={10} x={5} y={5} rx={3} />
                                        <rect width={10} height={10} x={17} y={5} rx={3} />
                                        <rect width={10} height={10} x={5} y={17} rx={3} />
                                        <rect width={10} height={10} x={17} y={17} rx={3} />
                                    </g>
                                </svg>

                            </li> */}


                            {!isLogin && (<>

                                <li className="d-mob-none">
                                    <Link to="/login">login</Link>
                                </li>

                                {/* <li className="d-mob-none">
                                    <Link to="/signup">Signup</Link>
                                </li> */}

                            </>)}


                            {isLogin && (<>


                                <li className="d-mob-none" >
                                    <Link to="/account"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
                                        <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
                                    </svg>

                                    </Link></li>

                                <li className="d-mob-none">
                                    <Link onClick={handleLogout} >Logout</Link>
                                </li>
                            </>)}

                        </ul>
                    </div>


                </div>
            </nav >

            <div className={isCartActive ? 'cart-sidebar Isactive' : 'cart-sidebar'} >

                <Cart />
            </div>


            <div style={{ overflow: "hidden" }}>


                <div className="row d-mob-none py-1 bg-green" />
            </div>



            <section >
                <div className="col-md-12 px-0" >
                    <nav className="nav px-0 mx-0">
                        <ul className="link-list mx-auto">

                            <li>
                                <Link className="nav-link fw-light" onClick={toggleMenu}
                                    id="mobile_menu_trigger" >ALL Menu</Link >

                            </li>


                            {isHeader
                                ? // Display loading skeletons while data is being fetched
                                Array.from({ length: 8 }).map((_, index) => (
                                    <div className="nav-item me-2 mt-1" key={index}>
                                        <div
                                            className="skeleton mt-1"
                                            style={{
                                                height: 22,
                                                width: 100,
                                                borderRadius: 5,
                                            }}
                                        ></div>
                                    </div>
                                ))
                                : // Render first-level menu items
                                Headers.header.slice(0, 8).map((item) => {
                                    return (
                                        <li className="nav-item " key={item.id}>
                                            <Link
                                                className={`nav-link fw-light ${item.children && item.children.length > 0 ? 'dropdown-toggle' : ''}`}
                                                to={item.children && item.children.length > 0 ? '#' : item.link}
                                                {...(item.children && item.children.length > 0 && { 'data-bs-toggle': 'dropdown' })}
                                            >
                                                {item.text}
                                            </Link>



                                            {item.children && item.children.length > 0 && (
                                                <>


                                                    <ul className="dropdown-menu">
                                                        {item.children.slice(0, 8).map(child => (
                                                            <>

                                                                <li><Link to={child.link} target={child.target === '_blank' ? '_blank' : ''} className="dropdown-item">{child.text}</Link ></li>

                                                            </>
                                                        ))}

                                                    </ul>

                                                </>
                                            )}





                                        </li>
                                    );
                                })}

                        </ul>
                    </nav>
                </div>

            </section>





            <div id="mobile_menu_container" className="">
                <div id="mobile_menu_container_inner">
                    <div className="mobile_menu_top">
                        <Link to="/">
                            <img src={'../../../assets/images/bmnlogo.png'} />
                        </Link>
                        <div className="mobile_menu_close">
                            <i class="ri-close-line"></i>
                        </div>
                    </div>
                    <div id="mobile_menu_image_links"></div>

                    <ul id="mobile_menu">
                        {Headers.header && Headers.header.length > 0 && Headers.header.map(item => (
                            <MenuItem key={item.id} item={item} />
                        ))}
                    </ul>



                </div>
            </div>

            <div style={{ overflow: "hidden" }}>


                <div className="row d-mob-none py-1 pinkhead" />
            </div>

        </>
    )
}

export default Header