import React, { useState, useEffect, useContext, Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AccountSidebar from '../components/AccountSidebar';
import { toast } from 'react-hot-toast';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axiosInstance from '../../api/axiosInstance';

const AccountOrder = () => {


    const [Order, setOrder] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getUserOrders = async () => {
        try {
            const id = localStorage.getItem('userId');
            const { data } = await axiosInstance.get(`/user-orders/${id}`);
            console.log(id);
            if (data?.success) {
                setOrder(data?.userOrder.orders.reverse());
            }
            setIsLoading(false); // Set loading state to false after fetching data
        } catch (error) {
            console.log(error);
            setIsLoading(false); // Set loading state to false in case of an error

        }
    };

    useEffect(() => {
        getUserOrders();
    }, []); // Empty dependency array ensures that the effect runs once after the initial render

    useEffect(() => {
        // This useEffect runs whenever the 'blog' state variable changes
        console.log(Order); // Log the updated 'blog' state
    }, [Order]); // Dependency array with 'blog' ensures the effect runs when 'blog' state changes


    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-US', options);
    }

    return (
        <>
            <Header />
            <section id="mid">
                <div className="container mt-3">
                    <div className="row">
                        <AccountSidebar />
                        <div className="col-md-9">
                            <h2>My orders</h2>

                            <table className="table table-hover table-success table-striped table-bordered border-dark">
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Product Name</th>

                                        <th>Total Price</th>
                                        <th>Payment</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>


                                    {isLoading ? (
                                        // Display loading skeletons while data is being fetched
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <tr>

                                                <td colSpan={'100'}>
                                                    <div className="col-md-12 col-lg-12" key={index}>

                                                        <div className="blog-entry mb-4">
                                                            <div className="skeleton mb-3" style={{ height: 50, borderRadius: 10 }}>

                                                            </div> </div>

                                                    </div>

                                                </td>


                                            </tr>
                                        ))
                                    ) :
                                        (Order.map(Order => (
                                            <>


                                                <tr>
                                                    <td>
                                                        {Order._id}

                                                    </td>



                                                    <td>
                                                        {Order.items.map(item => (
                                                            <>

                                                                <div className='position-relative' style={{ width: 60, height: 80, overflow: 'hidden ' }}>
                                                                    <img
                                                                        src={item.image}
                                                                        style={{ width: 60, height: 80, overflow: 'hidden ' }}
                                                                    />




                                                                </div>


                                                                {item.title} - {item.color} -
                                                                <br />
                                                                [ {
                                                                    Object.entries(item.SelectedSizes).map(([key, value]) => (
                                                                        <><span key={key}>{`${key}: ${value},`}</span>
                                                                        </>
                                                                    ))
                                                                } ]


                                                                <hr />
                                                            </>
                                                        ))}


                                                    </td>


                                                    <td>
                                                        £{Order.totalAmount}

                                                    </td>


                                                    <td>
                                                        {Order.mode}

                                                    </td>

                                                    <td>  {formatDate(Order.createdAt)} </td>


                                                    <td>

                                                        <div className="overlay ">
                                                            <Link className="btn btn-primary mx-auto mt-3" to={`/account/order/${Order._id}`} fdprocessedid="t65pw">
                                                                <span>View  </span>
                                                            </Link>
                                                        </div>

                                                    </td>
                                                </tr >

                                            </>
                                        ))
                                        )}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}

export default AccountOrder