import { useRef, useState, useEffect } from 'react';
import { useBlogContext } from '../../fetchdata/BlogContext';
import Header from '../components/Header'; // Replace this with your Header component
import Footer from '../components/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import axiosInstance, { backurl } from '../../api/axiosInstance';

const Category = () => {

    const [layout, setLayout] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isProducts, setIsProducts] = useState(true);



    const startMin = 0;
    const startMax = 100;
    const min = 0;
    const max = 200;
    const step = 1;

    const [selectedRanges, setSelectedRanges] = useState([]);

    const [selectedFilters, setSelectedFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [Cat, setCat] = useState([]);
    const [CatT, setTCat] = useState("");
    const [Pro, setPro] = useState([]);
    const [Attr, setAttr] = useState([]);
    const [fillter, setfillter] = useState('');

    const [isCat, setIsCat] = useState(true);
    const [count, setcount] = useState('100');

    const handlecountChange = (event) => {
        setcount(event.target.value);
    };

    const { id } = useParams();
    const [products, setProducts] = useState([]);


    const getCategory = async (fillter, page, price) => {
        try {
            window.scrollTo(0, 0);
            setIsCat(true);
            const encodedFilter = encodeURIComponent(fillter); // Encode the filter string

            const { data } = await axiosInstance.get(`/all/category/${id}?filter=${encodedFilter}&price=${price}&page=${page}&perPage=${count}`);
            setCat([]);
            setTCat("");

            if (data?.success) {
                setCat(data?.categories);
                setTCat(data.MainCat.title);

                setTotalPages(data?.proLength);
                setfillter(fillter);

                // Set products first
                setProducts(data?.productsFilter);


                setPro(data?.products);

            }
        } catch (error) {
            console.log(error);
            toast.error("Error fetching Category!");
        } finally {
            setIsCat(false);
        }
    };



    const getAttribute = async () => {
        try {
            const { data } = await axiosInstance.get("/all-attribute");
            console.log("attr", data);
            setAttr(data.Attribute);
        } catch (error) {
            console.error("Error fetching attributes:", error);
        }
    };

    // Function to handle checkbox selection
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedFilters((prevState) => {
            // If checked, add the value to selectedFilters, otherwise remove it
            if (checked) {
                return { ...prevState, [value]: checked };
            } else {
                const { [value]: removedValue, ...rest } = prevState;
                return rest;
            }
        });
        setCurrentPage(1);

    };

    const generateQueryString = () => {
        const filterObj = {};

        // Construct filter object from selected filters
        Object.entries(selectedFilters).forEach(([key, value]) => {
            const [attrKey, attrValue] = key.split(",");
            filterObj[attrKey] = attrValue;
        });

        // Convert filter object to JSON string
        const filterString = JSON.stringify(filterObj);

        console.log(filterString); // Output the generated filter string
        setTimeout(function () { getCategory(filterString, currentPage, selectedRanges); }, 100);


    };

    const handleCategoryLinkClick = (categoryId) => {
        getCategory(fillter, currentPage, selectedRanges);
    };

    useEffect(() => {
        generateQueryString(); // Call generateQueryString when selectedFilters change
        setIsCat(true); // Set loading state to true
        getAttribute();
        // getCategory(fillter,currentPage,selectedRanges);
    }, [id, currentPage, selectedFilters, selectedRanges, count]); // Empty dependency array ensures that the effect runs once after the initial render

    // useEffect(() => {
    //   generateQueryString(); // Call generateQueryString when selectedFilters change
    // }, [selectedFilters,selectedRanges]); // Add selectedFilters as a dependency

    // Function to filter products based on selected attributes

    const [priceRange, setPriceRange] = useState({ min: 0, max: 100 });

    function handleChange(e) {
        setPriceRange({
            priceRange,
            [e.target.name]: parseInt(e.target.value),
        });
    }


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };



    // Calculate the maximum sale price to determine the range
    const maxSalePrice = Math.max(...products.map(product => product.salePrice));

    // Determine the number of ranges based on the maximum sale price
    const numRanges = Math.ceil(maxSalePrice / 10000);

    // Generate price ranges dynamically
    const priceRanges = Array.from({ length: numRanges }, (_, index) => ({
        min: index * 10000 + 10000,
        max: (index + 1) * 10000 + 10000,
    }));

    // Filter out the price ranges that match the actual sale prices of your products
    const filteredPriceRanges = priceRanges.filter(range =>
        products.some(product =>
            product.salePrice >= range.min && product.salePrice < range.max
        )
    );

    useEffect(() => {
        console.log('filteredPriceRanges', filteredPriceRanges)
    }, [filteredPriceRanges]); // Empty dependency array ensures that the effect runs once after the initial render


    const handleCheckpriceboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedRanges([...selectedRanges, value]);
        } else {
            setSelectedRanges(selectedRanges.filter(range => range !== value));
        }
        setCurrentPage(1);
        console.log(selectedRanges)
    };


    return (

        <>

            <Header />


            <div className="container mx-auto my-2 py-2 bg-white" id="mens">

                <div className="row">

                    {isCat ? (

                        Array.from({ length: 4 }).map((_, index) => (
                            <div key={index} className="col-lg-3 col-md-3 col-sm-6 mt-2">

                                <div className="card-1 skeleton" style={{ height: 264, borderRadius: 20 }} >

                                </div>
                            </div>

                        ))
                    ) :

                        (Cat.map(Cat => (

                            <>



                                <div className="col-md-3">
                                    <Link to={`/category/${Cat._id}`} class="card">
                                        <img src={`${Cat.image}`} alt="" className="img" />
                                        <div class="textBox">
                                            <h5 class="text head">{Cat.title}</h5>
                                        </div>
                                    </Link>
                                </div>

                            </>
                        )))
                    }






                </div>



            </div>




            <section id="section2 my-4">

                <div className="conatianer-fluid my-5">
                    <div className="row mx-5 my-0">
                        <div className="col-md-2 ">
                            {/* filter start  */}



                            <div className="filter  filter-panel ms-2">
                                <br />
                                <div>
                                    {/* <h3 className="widget-title">Price</h3> */}

                                    <ul className="widget-list pt-1">


                                        {filteredPriceRanges.map((range, index) => {
                                            const isChecked = selectedRanges.includes(`${range.min}-${range.max}`);
                                            return (
                                                <li key={index} className="mb-1">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id={`checkbox-${index}`}
                                                            value={`${range.min}-${range.max}`}
                                                            onChange={handleCheckpriceboxChange}
                                                            checked={isChecked}
                                                        />
                                                        <label className="form-check-label" htmlFor={`checkbox-${index}`}>
                                                            ${range.min} - ${range.max}
                                                        </label>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>



                                </div>

                                <hr />



                                <div>


                                    <div className="accordion" id="accordionExample">

                                        {Attr.length > 0 ? (
                                            <>
                                                {
                                                    Attr.map((row, index) => (
                                                        <>
                                                            {row.name === 'Color' ? (

                                                                <>
                                                                    <label>Colours</label>
                                                                    <div className="color-options">


                                                                        {
                                                                            row.value.map((value, index) => (
                                                                                <label key={index}>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        style={{ display: "none" }}
                                                                                        name="color"
                                                                                        value={`${row.name},${value}`}
                                                                                        onChange={handleCheckboxChange}
                                                                                    />

                                                                                    <span className="color-circle" style={{ backgroundColor: `${row.color[index]}` }}></span>
                                                                                    <span className="hovercolor">{value}</span>
                                                                                </label>
                                                                            ))
                                                                        }


                                                                    </div>
                                                                    <hr />


                                                                </>

                                                            ) : (

                                                                <div className="accordion-item">
                                                                    <h2 className="accordion-header" id={`heading${index + 1}`}>
                                                                        <button
                                                                            className="accordion-button"
                                                                            type="button"
                                                                            data-bs-toggle="collapse"
                                                                            data-bs-target={`#collapseOne${index + 1}`}
                                                                            aria-expanded="true"
                                                                            aria-controls="collapseOne"
                                                                        >
                                                                            {row.name}
                                                                        </button>
                                                                    </h2>
                                                                    <div
                                                                        id={`collapseOne${index + 1}`}
                                                                        className="accordion-collapse collapse show"
                                                                        aria-labelledby={`heading${index + 1}`}
                                                                    >
                                                                        <div className="accordion-body">

                                                                            {
                                                                                row.value.map((val, index) => (
                                                                                    <label className="checkbox-label">
                                                                                        <input type="checkbox" className="checkbox-input" value={`${row.name},${val}`} onChange={handleCheckboxChange} /> {val}
                                                                                    </label>

                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )}

                                                        </>
                                                    ))
                                                }
                                            </>

                                        ) : (

                                            < div > Loading .....</div>
                                        )}
                                    </div>


                                </div>


                            </div>



                            {/* Filter end */}
                        </div>


                        <div className="col-md-10 ">

                            <div className="col">
                                <div className="row g-2">
                                    <label
                                        htmlFor="sorting"
                                        className="col-auto col-form-label d-none d-sm-block"
                                    >
                                        No of products
                                    </label>
                                    <div className="col-auto">
                                        <select className="form-select" id="sorting" value={count} onChange={handlecountChange} >
                                            <option value="50">50</option>
                                            <option value="100">100</option>

                                            <option value="200">200</option>

                                            <option value="500">500</option>

                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="row">



                                {isCat ? (

                                    Array.from({ length: 6 }).map((_, index) => (
                                        <div key={index} className="col-lg-4 col-md-4 col-sm-6 mt-4">

                                            <div className="card-1 skeleton" style={{ height: 400, borderRadius: 5 }} >

                                            </div>
                                        </div>

                                    ))
                                ) :
                                    <>

                                        {Pro.map(Pro => (

                                            <>

                                                <div className="col-md-3"   >


                                                    <div className="card1 mt-4" style={{ height: '100%' }}>
                                                        <div classname="price m-0" >

                                                            <img src={`${Pro.pImage}`} alt="t-shirt" width={'100%'} style={{ aspectRatio: "1/0.7", objectFit: "cover", objectPosition: "top" }}
                                                            />

                                                            <Link to={`/product/${Pro._id}`} className="bg-dark d-block text-decoration-none text-white text-center py-2">
                                                                SHOP NOW
                                                            </Link>
                                                            <h4 className="fw-medium h6 text-center mt-2">  {Pro.title} </h4>
                                                            <div className="col text-center mb-0">
                                                                <Link className="price text-decoration-none text-pink"
                                                                    to={`/product/${Pro._id}`}><del className="fw-light text-dark">£{Pro.regularPrice}</del> <span className="text-pink"> £{Pro.salePrice}  </span></Link>
                                                            </div>
                                                        </div>



                                                    </div>


                                                </div>



                                                <div className="col-md-4 d-none">


                                                    <div className="card1 mt-4">
                                                        <Link classname="price" to={`/product/${Pro._id}`}>
                                                            <figure>
                                                                <img src={`${Pro.pImage}`} alt="t-shirt" width={'100%'} />
                                                            </figure>
                                                        </Link>
                                                        <section className="details">
                                                            <div className="min-details">
                                                                <h4>  {Pro.title} </h4>
                                                                <Link classname="price" to={`/product/${Pro._id}`}><del>${Pro.regularPrice}</del> ${Pro.salePrice} </Link>
                                                            </div>



                                                            <Link to="/" className="btn1">Add to cart</Link>



                                                        </section>
                                                    </div>


                                                </div >
                                            </>
                                        ))}

                                        {Pro.length === 0 && (
                                            <div className="container mx-auto my-2 mt-5 py-2 bg-white text-center">
                                                <p>No products found for this category.</p>
                                            </div>
                                        )}


                                        <div className="d-flex justify-content-between mt-6 container w-80 m-auto mt-5">
                                            <ul className="pagination">
                                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                    <button className="page-link" type="button" onClick={() => handlePageChange(currentPage - 1)}><i className="ri-arrow-left-s-line me-1"></i>Prev</button>
                                                </li>
                                            </ul>
                                            {/* <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
              <button className="page-link"  type="button" onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
            </li>
          ))}
        </ul> */}
                                            <ul className="pagination">
                                                <li className={`page-item ${totalPages === 0 ? 'disabled' : ''}`}>
                                                    <button className="page-link" type="button" onClick={() => handlePageChange(currentPage + 1)} aria-label="Next">Next<i className="ms-1 ri-arrow-right-s-line"></i></button>
                                                </li>
                                            </ul>
                                        </div>



                                    </>


                                }



                            </div>
                        </div>


                    </div>
                </div>
            </section >


            <Footer />
        </>

    )
}

export default Category