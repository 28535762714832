import React, { useState, useEffect } from 'react';
import AccountSidebar from '../components/AccountSidebar';

import { toast } from 'react-hot-toast';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';

const Accountprofile = () => {

    const [loading, setLoading] = useState(true); // Add loading state
    const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
    const [data, setData] = useState([]);

    const [formData, setFormData] = useState({
        username: '',
        phone: '',
        email: '',
        pincode: '',
        address: '',
        state: '',
    });


    const submitData = async () => {
        setSubmitLoading(false);
        const id = localStorage.getItem('userId');
        try {
            if (id) {
                await axiosInstance.put(`/update-profile/${id}`, formData);
                toast.success('Profile Updated!');
            }
        } catch (error) {
            console.error('Error On Profile:', error);
            console.log(formData);
            toast.error(error.response.data.message);
        } finally {
            setSubmitLoading(true);
        }

    };

    //form handle
    const fetchUserById = async () => {

        const token = localStorage.getItem('token');
        const id = localStorage.getItem('userId');
        const credentials = {
            token: token,
            id: id,
        };

        try {
            const { data } = await axiosInstance.post('/auth-user', credentials);
            const { success, token, existingUser, message } = data;

            if (success) {
                setFormData((prevData) => ({
                    ...prevData,
                    username: existingUser.username || '',
                    phone: existingUser.phone || '',
                    email: existingUser.email || '',
                    address: existingUser.address || '',
                    pincode: existingUser.pincode || '',
                    state: existingUser.state || '',
                    country: existingUser.country || '',

                }));

            }

            console.log('success', existingUser)

        } catch (error) {
            console.error('Error during login:', error);
            // Handle network errors, API issues, etc.
            toast.error(error.response.data.message);

        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
    };


    useEffect(() => {
        fetchUserById();
    }, []);




    return (
        <>
            <Header />
            <section id="mid">
                <div className="container mt-3">
                    <div className="row">
                        <AccountSidebar />
                        <div className="col-md-9">
                            <h2>My Profile</h2>

                            <div className="details_card">

                                <div className=" row p-2">

                                    <div className='col-md-12 mb-2'>
                                        <div className="form-group">
                                            <h4>Full Name</h4>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                                name="username"
                                                value={formData.username}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                    <div className='col-md-6 mb-2'>
                                        <div className="form-group">
                                            <h4>Email Id</h4>

                                            <input
                                                type="text"
                                                className="form-control"
                                                name="email"
                                                value={formData.email}
                                                readOnly
                                            />
                                        </div>
                                    </div>


                                    <div className='col-md-6 mb-2'>
                                        <div className="form-group">
                                            <h4>Phone No.</h4>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <div className="form-group">
                                            <h4>Country</h4>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                                name="country"
                                                value={formData.country}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <div className="form-group">
                                            <h4>Pincode</h4>

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="formGroupExampleInput"
                                                name="pincode"
                                                value={formData.pincode}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-12 mb-2">
                                        <h4>Shipping Address</h4>
                                        <textarea
                                            className="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows={3}
                                            name="address"
                                            value={formData.address}
                                            onChange={handleChange}
                                        />


                                    </div>

                                </div>

                                <div className='overlay '>


                                    {SubmitLoading ? (
                                        <button
                                            type="button"
                                            id="submit"
                                            name="submit"
                                            className="btn2 mx-auto mt-3" onClick={submitData} >
                                            <span>Update </span>

                                        </button>

                                    ) : (

                                        <button className="btn2 mx-auto mt-3" type="button" disabled>
                                            <span class="ms-1">Loading...</span>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>

                                    )}


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}

export default Accountprofile