import React, { useState, useEffect, useContext, Component } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import toast from "react-hot-toast";
import { Helmet } from 'react-helmet';
import Header from '../components/Header'
import Footer from '../components/Footer';
import axiosInstance from '../../api/axiosInstance';


const Blog = () => {

    const [blog, setBlog] = useState({});
    const { slug } = useParams();

    const getBlog = async () => {
        try {
            const { data } = await axiosInstance.get(`/get-blog/${slug}`);
            setBlog(data.blog);
            // Delay the execution of buildScript by 1 secon

        }
        catch (error) {
            console.log(error);
            toast.error("Error fetching Single Blog!");
        }
    };

    useEffect(() => {
        getBlog();
    }, []); // Empty dependency array ensures that the effect runs once after the initial render

    return (
        <>
            <Header />
            <Helmet>
                <title>{blog.metaTitle}</title>
                <meta name="metaDescription" content={blog.title} />

            </Helmet>
            <div
                className="site-cover site-cover-sm same-height overlay single-page"
                style={{ backgroundImage: `url("${blog.image}")` }}
            >
                <div className="container">
                    <div className="row same-height justify-content-center">
                        <div className="col-md-6">
                            <div className="post-entry text-center">
                                <h1 className="mb-4">    {blog.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">

                <div className="container">

                    <div dangerouslySetInnerHTML={{ __html: blog.description }} />


                </div>

            </div>

            <Footer />
        </>

    )
}

export default Blog