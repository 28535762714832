import { useRef, useState, useEffect } from 'react';
import { useBlogContext } from '../../fetchdata/BlogContext';
import Header from '../components/Header'; // Replace this with your Header component
import Footer from '../components/Footer';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';

const PrivateStore = () => {



    const [formData, setFormData] = useState({
        isPassword: '',
        checkpassword: '',

    });


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    require('../assets/css/style.css')

    const [selectedFilters, setSelectedFilters] = useState({});
    const [Cat, setCat] = useState([]);
    const [CatT, setTCat] = useState([]);
    const [Data, setData] = useState({});
    const [ProductList, setProductsList] = useState([]);
    const [loading, setLoading] = useState(true); // Loader state
    const [isPassword, setPassword] = useState(false); // Loader state


    const [isCat, setIsCat] = useState(true);
    const { id } = useParams();

    const getProducts = async () => {
        try {
            const { data } = await axiosInstance.get('/all-privateproduct/');
            console.log('products', data);
            setData(data.products);
            const privateStore = async () => {
                try {
                    setIsCat(true);
                    const { data } = await axiosInstance.get(`/admin/get-privatestore/${id}`);
                    if (data?.success) {
                        setProductsList(data.Privatestore);
                        setLoading(false); // Set loading to false after data is fetched
                        console.log(data)
                        setFormData((prevData) => ({
                            ...prevData,
                            isPassword: data.Privatestore.password,
                        }));
                        if (data.Privatestore.passwordStatus === 'false') {
                            setPassword(true)
                        }
                    }
                } catch (error) {
                    console.log(error);
                    toast.error("Error fetching Single Blog!");
                } finally {
                    setIsCat(false);
                }
            };
            privateStore();
        } catch (error) {
            console.error('Error fetching Products:', error);
        }
    };


    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const checkpassword = async () => {

        if (formData.isPassword === formData.checkpassword) {
            toast.success("password is correct!");

            setPassword(true)
        } else {
            setPassword(false)
            toast.error("password is incorrect!");

        }

    }
    useEffect(() => {
        getProducts();
    }, []); // Empty dependency array ensures that the effect runs once after the initial render

    return (
        <>
            <Header />

            <hr></hr>




            <section id="section2 my-4">
                <div className="conatianer-fluid my-5">
                    <div className="row mx-5 my-0">
                        <div className="col-md-12 ">
                            <div class="row">
                                {loading ? ( // Render loader if loading is true
                                    <div className="mystorebox">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : (

                                    <>
                                        {!isPassword && (<>

                                            <div style={{
                                                minHeight: "50vh",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }} >
                                                <div className="col-md-4 m-auto">
                                                    <label htmlFor="inputPassword5" className="form-label">
                                                        Please enter the passcode to continue:

                                                    </label>

                                                    <input
                                                        type="password"
                                                        id="inputPassword5"
                                                        className="form-control"
                                                        aria-describedby="passwordHelpBlock"
                                                        name='checkpassword'
                                                        value={formData.checkpassword}
                                                        onChange={handleChange}
                                                    />

                                                    <div className="mt-4 overlay space col-lg-10 col-md-12 col-12  w-100">
                                                        <button fdprocessedid="7ecyo" onClick={checkpassword}>
                                                            <span>Continue</span>
                                                        </button>

                                                    </div>


                                                </div>

                                            </div>

                                        </>)}

                                        {isPassword && (<>
                                            <nav aria-label="breadcrumb">
                                                <ol class="breadcrumb">
                                                    <li class="breadcrumb-item"><Link to="/">Home</Link></li>
                                                    <li class="breadcrumb-item active" aria-current="page"> {ProductList.companyName}</li>
                                                </ol>
                                            </nav>

                                            <h1> {ProductList.companyName}</h1>

                                            <img src={ProductList.companyLogo} className="img-fluid" style={{ maxWidth: '500px' }} />

                                            <div className='row'>
                                                {
                                                    ProductList && ProductList.products.map(storeProductID => {
                                                        const matchingProduct = Data.find(product => product._id === storeProductID);
                                                        return matchingProduct ? (
                                                            <div key={matchingProduct._id} className="col-lg-3 col-md-3 col-sm-6 mt-4">
                                                                <div className="card1 mt-4" style={{ maxWidth: '100%' }}>
                                                                    <Link className="price" to={`/private-product/${matchingProduct._id}`}>
                                                                        <figure>
                                                                            <img src={matchingProduct.pImage} alt="t-shirt" width={'100%'} />
                                                                        </figure>
                                                                    </Link>
                                                                    <section className="details">
                                                                        <div className="min-details">
                                                                            <h4>{matchingProduct.title}</h4>
                                                                            <Link className="price" to={`/product/${matchingProduct._id}`}>
                                                                                <del>${matchingProduct.regularPrice}</del> ${matchingProduct.salePrice}
                                                                            </Link>
                                                                        </div>
                                                                        <Link to={`/collection/${id}/${matchingProduct._id}`} className="btn1">Add to cart</Link>
                                                                    </section>
                                                                </div>
                                                            </div>
                                                        ) : null;
                                                    })
                                                }

                                            </div>

                                        </>)}

                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <section>
                <div className="footer3">
                    <div className="row px-0 mx-0" id="frow">
                        <div className="col-md-12 " id="crt">
                            <h5 className="mt-2">Copyright © 2023, Brand Me Now. </h5>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivateStore;
