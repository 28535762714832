import React, { useState, useEffect, useContext, Component } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/store';
import { toast } from 'react-hot-toast';
import { useCart } from "react-use-cart";
import Header from '../components/Header';
import Footer from '../components/Footer';
import { loadStripe } from '@stripe/stripe-js';
import axiosInstance from '../../api/axiosInstance';


const Checkout = ({ updateAuthStatus }) => {


    const [vatActive, setVatActive] = useState(
        localStorage.getItem('vatActive') === 'true'
    );

    useEffect(() => {
        localStorage.setItem('vatActive', vatActive);
        console.log(vatActive)
    }, [vatActive]);





    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mode, setMode] = useState('');


    const isLoginFromLocalStorage = localStorage.getItem('token') ? true : false;
    const [isLogin, setIsLogin] = useState(isLoginFromLocalStorage);

    const [isLoginForm, setIsLoginForm] = useState(true); // State to manage which form to display

    const [isnextSubmit, setnextSubmit] = useState(false); // State to manage which form to display

    const toggleForm = () => {
        setIsLoginForm(prevState => !prevState); // Toggle between login and signup forms
    };

    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    const nextSubmit = () => {
        setnextSubmit(true);
    };

    const prevSubmit = () => {
        setnextSubmit(false);
    };

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
    } = useCart();

    const removeFromCart = (productId) => {
        removeItem(productId);
    };

    const [currentStep, setCurrentStep] = useState(1);

    const [hasPassword, setPassword] = useState(false); // Add loading state

    const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state
    const [GetOtpRes, setOtpRes] = useState([]); // Add loading state

    //handle input change
    const handleChange = (e) => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    };




    const submitOTP = async () => {
        setSubmitLoading(false); // Set loading to true before making the request

        try {
            const response = await axiosInstance.post(`/signup-login-otp/`, inputs); // Await the axios post request
            console.log('responseresponse', response)
            if (response.data.password === true) {
                setPassword(true)
            } else {
                handleNext();
                setOtpRes(response.data); // Set the response data to state
                toast.success('Otp Send successfully!');
                // setTimeout(function () { toast.success(`Your OTP  is ${response.data.otp}`); }, 1000);
            }
            console.log('dataotp', response.data.password);

        } catch (error) {
            console.error('Error On taxes:', error);
            toast.error(error.response.data.message);
        } finally {
            setSubmitLoading(true); // Set loading to false after request completion
        }
    };


    const submitLoginOTP = async () => {
        setSubmitLoading(false); // Set loading to true before making the request

        try {
            const response = await axiosInstance.post(`/login-with-otp/`, inputs); // Await the axios post request
            setOtpRes(response.data); // Set the response data to state
            console.log('dataotp', response.data);
            toast.success('Otp Send successfully!');
            // setTimeout(function () { toast.success(`Your OTP  is ${response.data.otp}`); }, 1000);
        } catch (error) {
            console.error('Error On taxes:', error);
            toast.error(error.response.data.message);
        } finally {
            setSubmitLoading(true); // Set loading to false after request completion


        }
    };


    const otpInputs = [];

    // Function to handle input change
    // Function to handle input change
    const handleInputChange = async (index, event) => {
        const input = event.target;
        const maxLength = parseInt(input.getAttribute('maxlength'));
        const currentLength = input.value.length;

        // Move focus to the previous input field if backspace is pressed on an empty input
        if (event.nativeEvent.inputType === 'deleteContentBackward' && currentLength === 0 && index > 0) {
            otpInputs[index - 1].focus();
        }

        // Move focus to the next input field if the current input is filled
        else if (currentLength === maxLength && index < otpInputs.length - 1) {
            otpInputs[index + 1].focus();
        }

        // Check if all OTP fields are filled and if the last input field is the one being typed
        const allFilled = otpInputs.every(input => input.value.trim() !== ''); // Check if all inputs have non-empty values
        if (allFilled && index === otpInputs.length - 1) { // Check if the last OTP field is filled
            const combinedValue = otpInputs.map(input => input.value.trim()).join(''); // Combine all values into a single string
            if (String(GetOtpRes.otp) === String(combinedValue)) {
                if (GetOtpRes.password === false) {

                    toast.success('Otp Verfied successfully!');
                    // Save token and user ID to localStorage
                    localStorage.setItem('token', GetOtpRes.token);
                    localStorage.setItem('userId', GetOtpRes.existingUser._id);

                    if (GetOtpRes) {
                        const { _id, username, email } = GetOtpRes.existingUser;
                        const userToStore = { _id, username, email };
                        localStorage.setItem('user', JSON.stringify(userToStore));
                    }

                    updateAuthStatus(true);
                    // Dispatch login action if you're using Redux
                    dispatch(authActions.login());
                    setIsLogin(true); // Set isLogin to true when token is found

                }

                else if (GetOtpRes.newUser === true) {
                    console.log('GetOtpResGetOtpRes', GetOtpRes)
                    try {
                        const response = await axiosInstance.post(`/signup-new-user/`, inputs); // Await the axios post request

                        if (response) {

                            toast.success('Otp Verfied New successfully!');
                            // Save token and user ID to localStorage
                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('userId', response.data.existingUser._id);

                            if (GetOtpRes) {
                                const { _id, username, email } = response.data.existingUser;
                                const userToStore = { _id, username, email };
                                localStorage.setItem('user', JSON.stringify(userToStore));
                            }

                            updateAuthStatus(true);
                            // Dispatch login action if you're using Redux
                            dispatch(authActions.login());
                            setIsLogin(true); // Set isLogin to true when token is found


                        }


                    } catch (error) {
                        console.error('Error On Signup:', error);
                        toast.error(error.response.data.message);
                    } finally {
                        setSubmitLoading(true); // Set loading to false after request completion
                    }


                } else {
                    toast.success('Otp Verfied successfully!');
                    // Save token and user ID to localStorage
                    localStorage.setItem('token', GetOtpRes.token);
                    localStorage.setItem('userId', GetOtpRes.existingUser._id);

                    if (GetOtpRes) {
                        const { _id, username, email } = GetOtpRes.existingUser;
                        const userToStore = { _id, username, email };
                        localStorage.setItem('user', JSON.stringify(userToStore));
                    }

                    updateAuthStatus(true);
                    // Dispatch login action if you're using Redux
                    dispatch(authActions.login());
                    setIsLogin(true); // Set isLogin to true when token is found
                }

            }
            else {
                toast.error('Please enter Valid OTP');
            }

        }
    };

    const submitLoginPass = async () => {
        setSubmitLoading(false); // Set loading to true before making the request

        try {
            const response = await axiosInstance.post(`/login-with-pass/`, inputs); // Await the axios post request
            setOtpRes(response.data); // Set the response data to state
            console.log('dataotp', response.data);
            if (response.data.checkpass === true) {

                toast.success('Login successfully!');
                // Save token and user ID to localStorage
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userId', response.data.existingUser._id);

                if (GetOtpRes) {
                    const { _id, username, email } = response.data.existingUser;
                    const userToStore = { _id, username, email };
                    localStorage.setItem('user', JSON.stringify(userToStore));
                }

                updateAuthStatus(true);
                // Dispatch login action if you're using Redux
                dispatch(authActions.login());
                setIsLogin(true); // Set isLogin to true when token is found


            }
        } catch (error) {
            console.error('Error On taxes:', error);
            toast.error(error.response.data.message);
        } finally {
            setSubmitLoading(true); // Set loading to false after request completion

        }
    };



    useEffect(() => {
        const checkUserToken = async () => {
            console.log('Effect is running');
            // Check if this is printed multiple times
            const usertoken = localStorage.getItem('token');
            if (usertoken) {
                console.log('Token found in local storage'); // Check if this is printed multiple times

                toast.success("Welcome back");
            }
        }
        checkUserToken();
    }, [dispatch, navigate]);



    const [inputs, setInputs] = useState({
        email: '',
        Gtoken: 'sddwdwdwdd',
        password: '',
    });



    const [Logininputs, setLoginInputs] = useState({
        email: '',
        password: '',
    });



    const Logincredentials = {
        email: Logininputs.email,
        password: Logininputs.password
    };


    //handle input change
    const handleLoginChange = (e) => {
        setLoginInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    };

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };


    //form handle
    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axios.post('/login', Logincredentials);
            const { success, token, user, message } = data;

            if (success) {
                // Save token and user ID to localStorage
                localStorage.setItem('token', user.token);
                localStorage.setItem('userId', user._id);

                toast.success("login sucesssfully");
                console.log('user', user)

                if (user) {
                    const { _id, username, email } = user;
                    const userToStore = { _id, username, email };
                    localStorage.setItem('user', JSON.stringify(userToStore));
                }

                // Dispatch login action if you're using Redux
                dispatch(authActions.login());
                setIsLogin(true); // Set isLogin to true when token is found

                // Redirect user or perform other actions upon successful login
            }
            console.log("Message from backend:", message);

        } catch (error) {
            console.error('Error during login:', error);
            // Handle network errors, API issues, etc.
            toast.error(error.response.data.message);

        }
    };

    const userString = localStorage.getItem('user');
    let initialUserData = {
        phone: '',
        pincode: '',
        country: '',
        address: ''
    };

    if (userString) {
        const { phone, pincode, country, address } = JSON.parse(userString);
        // Use user information from localStorage as initial data
        initialUserData = { phone, pincode, country, address };
    }



    // Set initial state using retrieved user information
    const [Orderinputs, setOrderInputs] = useState(initialUserData);

    const [Ordercart, setOrderCart] = useState({});
    const [OrderLoading, setOrderLoading] = useState(false);


    const Ordercredentials = {
        phone: Orderinputs.phone,
        pincode: Orderinputs.pincode,
        country: Orderinputs.country,
        address: Orderinputs.address,
        state: Orderinputs.state,
        username: Orderinputs.username,
        email: Orderinputs.email,

        items: Ordercart,
        status: '1',
        mode: 'cod',
        details: {
            phone: Orderinputs.phone,
            pincode: Orderinputs.pincode,
            country: Orderinputs.country,
            address: Orderinputs.address,
            username: Orderinputs.username,
            state: Orderinputs.state,
            email: Orderinputs.email,
        },
        totalAmount: cartTotal + items
            .flatMap(item => item.mappedData.map(data => data.customtax)) // Extract all customtax values
            .reduce((acc, curr) => acc + curr, 0),
    };




    useEffect(() => {
        const userCart = localStorage.getItem('react-use-cart');
        if (userCart) {
            const { items } = JSON.parse(userCart);
            setOrderCart(items);
        }
    }, []); // Empty dependency array to run this effect only once when the component mounts




    //handle input change
    const handleOrderChange = (e) => {
        setOrderInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    };


    const handleOrderSubmit = async (e) => {
        e.preventDefault();

        const userId = localStorage.getItem('userId');

        function generateRandomString(length) {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            const charactersLength = characters.length;

            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            return result;
        }

        const randomKey = generateRandomString(50); // Assuming you have a generateRandomString function defined

        // Create the order data to send to the backend
        const orderData = {
            ...Ordercredentials,
            token: randomKey,
            userId: userId,
            mode: mode,
        };

        console.log(orderData)
        if (!Orderinputs.username || !Orderinputs.phone || !Orderinputs.email || !Orderinputs.pincode || !Orderinputs.address) {
            toast.error('Please Fill All Details');

        } else if (mode === '' || !mode) {
            toast.error('Please Choose Payment Mode');
        } else {
            try {
                setOrderLoading(true)
                // Send the order data to the backend to create a new order
                const response = await axiosInstance.post(`/create-order/${userId}`, orderData);
                if (mode !== 'Stripe') {
                    toast.success('congratulations order sucess placed');
                    emptyCart();
                    navigate('/account/orders')
                } else {
                    // If the order creation is successful
                    if (response && response.data && response.data.id) {
                        // Retrieve the session ID from the response
                        const sessionId = response.data.id;

                        // Load Stripe instance with your publishable key
                        const stripe = await loadStripe("pk_live_51HFbsWFeWFRa3XwURJyMzwP7ZTPB5q5HY6cSOqseSeR6zUgCmWZbHGqeLaRay7UIuhryd51cly5s5fSQg3gYNfJY00Nvk9omG7");

                        // Redirect the user to the checkout page using the session ID
                        const { error } = await stripe.redirectToCheckout({
                            sessionId: sessionId
                        });

                        // Handle any errors during redirection
                        if (error) {
                            console.error("Error redirecting to checkout:", error);
                            // Handle the error gracefully (e.g., show an error message to the user)
                        }
                    } else {
                        // Handle the case when the order creation fails
                        console.error("Order creation failed:", response);
                        // Show an error message to the user
                        toast.error("Failed to create the order. Please try again.");
                    }

                }


            } catch (error) {
                // Handle network errors, API issues, etc.
                console.error('Error during order submission:', error);
                // Show an error message to the user
                toast.error("An unexpected error occurred. Please try again later.");
            } finally {
                setOrderLoading(false)
            }

        }

    };



    const credentials = {
        username: inputs.username,
        email: inputs.email,
        password: inputs.password
    };
    const [loginError, setLoginError] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add loading state


    //handle input change
    const handleSignupChange = (e) => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));

    };

    //form handle
    const handleSignupSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data } = await axiosInstance.post('/signup', credentials);
            const { success, token, user, message } = data;

            if (success) {
                // Save token and user ID to localStorage
                localStorage.setItem('token', user.token);
                localStorage.setItem('userId', user._id);

                toast.success("login sucesssfully");

                // Dispatch login action if you're using Redux
                dispatch(authActions.login());
                setIsLogin(true); // Set isLogin to true when token is found

                // Redirect user or perform other actions upon successful login
            }
            console.log("Message from backend:", message);

        } catch (error) {
            console.error('Error during login:', error);
            // Handle network errors, API issues, etc.
            toast.error(error.response.data.message);

        }
    };

    useEffect(() => {
        setIsLogin(isLoginFromLocalStorage);
    }, [isLoginFromLocalStorage, dispatch]);


    useEffect(() => {


        if (isLogin === true) {



            //form handle
            const fetchUserById = async () => {

                const token = localStorage.getItem('token');
                const id = localStorage.getItem('userId');
                const credentials = {
                    token: token,
                    id: id,
                };

                try {
                    const { data } = await axiosInstance.post('/auth-user', credentials);
                    const { success, existingUser } = data;

                    if (success) {

                        setOrderInputs((prevData) => ({
                            ...prevData,
                            username: existingUser.username || '',
                            phone: existingUser.phone || '',
                            email: existingUser.email || '',
                            address: existingUser.address || '',
                            pincode: existingUser.pincode || '',
                            state: existingUser.state || '',
                            country: existingUser.state || '',

                        }));

                        console.log("Message from backend:", existingUser);
                    }


                } catch (error) {
                    console.error('Error during login:', error);
                    // Handle network errors, API issues, etc.
                    toast.error(error.response.data.message);

                }
            };
            fetchUserById();
        }

    }, [isLogin]);



    return (


        <>
            <Header />


            <div className="container row mt-5 position-relative">
                <div className="payment_details col-md-8">
                    <h1>Payment Information</h1>

                    <div className="details_card">



                        {!isLogin && (

                            <div className="col-12 col-lg-6 m-auto rounded-3 shadow p-5 bg-white" style={{ maxWidth: 450, minHeight: 400 }}>

                                {currentStep === 1 && (
                                    <>
                                        {!hasPassword ? (<>
                                            <h4 className="border-bottom pb-4 mb-4 text-center">Login <span className=""> or</span> Signup</h4>
                                            <div className="needs-validation" noValidate="">
                                                <div className="row g-4">

                                                    <div className="col-12">
                                                        <div className="col-auto">

                                                            <div className="input-group mb-2">

                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Email Id*"
                                                                    value={inputs.email}
                                                                    onChange={handleChange} // Add onChange handler to manage input changes
                                                                    onKeyPress={(e) => {
                                                                        if (e.key === 'Enter' && inputs.email.length === 10) {
                                                                            submitOTP();
                                                                        }
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-12 ">
                                                        <p>By continuing, I agree to the Terms of Use & Privacy Policy</p>

                                                        {SubmitLoading ? (

                                                            <button
                                                                className="btn btn-success d-flex align-items-center justify-content-center w-100" onClick={() => {

                                                                    submitOTP();
                                                                }}
                                                                type="button">
                                                                Continue
                                                            </button>

                                                        ) : (

                                                            <button className="btn btn-success d-flex align-items-center justify-content-center w-100" type="button" disabled>
                                                                <span class="ms-1">Loading...</span>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            </button>

                                                        )}


                                                    </div>
                                                </div>
                                            </div>
                                        </>) : (<>
                                            <h4 className="border-bottom pb-4 mb-4 text-center">OTP <span className=""> or</span> Password</h4>

                                            <div className="col-8 m-auto mt-10">
                                                <button className="btn btn-accent btn-shadow d-block w-100 mt-10" type="button" onClick={() => {
                                                    handleNext()
                                                    submitLoginOTP();
                                                }}
                                                >Login With OTP</button>
                                                <hr class="or" />
                                                <button className="btn btn-accent btn-shadow d-block w-100" type="button" onClick={() => setCurrentStep(3)} >Login With Password</button>

                                            </div>
                                        </>)}


                                    </>)}

                                {currentStep === 2 && (
                                    <>

                                        <div className="card-body p-lg-5 p-2 text-center">
                                            {!hasPassword ? (<>
                                                <h4>Verify with OTP</h4>
                                                <p>Send to    <a onClick={handlePrevious} previewlistener="true" href="#" >Edit  </a>   {inputs.phone}</p>

                                                <div className="d-flex gap-3 col-md-12 m-auto mb-4">
                                                    {[...Array(4)].map((_, index) => (
                                                        <div key={index} className="col">
                                                            <input
                                                                type="text"
                                                                name="otp"
                                                                className="form-control text-center p-2"
                                                                placeholder=""
                                                                maxLength={1} // Set the maximum length to 1 character
                                                                onInput={(event) => handleInputChange(index, event)} // Handle input change
                                                                ref={(input) => otpInputs.push(input)} // Add a reference to the input field
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <p className="resend text-muted mb-0">
                                                    Didn't receive code?{" "}
                                                    <a href="#" onClick={submitOTP} >
                                                        Request again
                                                    </a>
                                                </p>
                                                {/* <hr></hr>
<p className="resend text-muted mb-0">
Login Using{" "}
<a href="" previewlistener="true">
Password
</a>
</p> */}
                                            </>
                                            ) : (<>

                                                <h4>Verify with OTP</h4>
                                                <p>Send to    <a onClick={handlePrevious} previewlistener="true" href="#" >Edit  </a>   {inputs.phone}</p>

                                                <div className="d-flex gap-3 col-md-12 col-9 m-auto mb-4">
                                                    {[...Array(4)].map((_, index) => (
                                                        <div key={index} className="col">
                                                            <input
                                                                type="text"
                                                                name="otp"
                                                                className="form-control text-center"
                                                                placeholder=""
                                                                maxLength={1} // Set the maximum length to 1 character
                                                                onInput={(event) => handleInputChange(index, event)} // Handle input change
                                                                ref={(input) => otpInputs.push(input)} // Add a reference to the input field
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <p className="resend text-muted mb-0">
                                                    Didn't receive code?{" "}
                                                    <a href="#" onClick={submitLoginOTP} >
                                                        Request again
                                                    </a>
                                                </p>

                                            </>)}

                                        </div>

                                    </>
                                )}
                                {currentStep === 3 && (
                                    <>

                                        <h4 className="border-bottom pb-4 mb-4 text-center">Login With Password</h4>
                                        <p className="mb-2"> Please enter password that is linked to <br /> <b> {inputs.phone}  </b>  <a previewlistener="true" href="#" onClick={() => { setPassword(false); setCurrentStep(1) }} >Edit  </a> </p>


                                        <div className="needs-validation" noValidate="">
                                            <div className="row g-4">

                                                <div className="col-12">
                                                    <div className="col-auto">

                                                        <div className="input-group mb-2">

                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="password"
                                                                name="password"
                                                                placeholder="Enter Password*"
                                                                value={inputs.password}
                                                                onChange={handleChange} // Add onChange handler to manage input changes
                                                                onKeyPress={(e) => {
                                                                    if (e.key === 'Enter' && inputs.phone.length === 10) {
                                                                        submitLoginPass();
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {SubmitLoading ? (

                                            <button disabled={inputs.phone.length !== 10}
                                                className="btn btn-accent d-flex align-items-center justify-content-center w-100" onClick={() => {

                                                    submitLoginPass();
                                                }}
                                                type="button">
                                                Continue
                                            </button>

                                        ) : (

                                            <button className="btn btn-accent d-flex align-items-center justify-content-center w-100" type="button" disabled>
                                                <span class="ms-1">Loading...</span>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            </button>

                                        )}

                                    </>

                                )}

                            </div>

                        )}

                        {isLogin && (<>

                            {!isnextSubmit ? (<>
                                <div className='row'>


                                    <div className="col-md-6 mb-3">
                                        <input
                                            type="text"
                                            id="put"
                                            placeholder="Full Name"
                                            name="username"
                                            value={Orderinputs.username}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                        />
                                    </div>


                                    <div className="col-md-6 mb-3">
                                        <input
                                            type="email"
                                            id="email"
                                            placeholder="Email ID"
                                            name="email"
                                            value={Orderinputs.email}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                            readOnly
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input
                                            type="Number"
                                            id="put"
                                            placeholder="Phone No."
                                            name="phone"
                                            value={Orderinputs.phone}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <input type="number" placeholder="Pincode"
                                            name="pincode"
                                            value={Orderinputs.pincode}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                        />
                                    </div>

                                    <div className="col-md-6 mb-3">

                                        <input type="text" placeholder="State"
                                            name="state"
                                            value={Orderinputs.state}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                        />

                                    </div>

                                    <div className="col-md-6 mb-3">

                                        <input type="text" placeholder="Country"
                                            name="country"
                                            value={Orderinputs.country}
                                            onChange={handleOrderChange}
                                            className="m-0"
                                        />

                                    </div>
                                </div>


                                <div className=" p-3">
                                    <h4>Shipping Address</h4>
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                        name="address"
                                        value={Orderinputs.address}
                                        onChange={handleOrderChange}
                                    ></textarea>

                                </div>


                                <div className="proced_payment">
                                    <button
                                        type="submit"
                                        className="btnsub  form-control  mt-3"
                                        style={{ width: 200, marginTop: "30px !important", marginLeft: 'auto' }}
                                        // onClick={handleOrderSubmit}
                                        onClick={nextSubmit}
                                    >
                                        Continue
                                    </button>

                                </div>

                            </>) : (<>

                                <div className='text-start'>
                                    <button
                                        type="submit"
                                        className="btnsub  form-control  mt-3 text-white bg-dark"
                                        style={{ width: 'auto', marginTop: "30px !important" }}
                                        // onClick={handleOrderSubmit}
                                        onClick={prevSubmit}
                                    >
                                        <i class="ri-arrow-left-s-line  text-white"></i>  {' '}
                                        Back
                                    </button>


                                </div>




                                <div className="overlay space row w-100 custombx">

                                    <div className='row'>


                                        <div className="col-12">
                                            <h4>Choose Payment Method</h4>
                                        </div>
                                        <div className="row mycustom-radio">
                                            <div className="col-6 text-center">
                                                <input type="radio" checked={mode === "COD"} value={'COD'} name="mode" id="mode1" onChange={handleModeChange}
                                                />

                                                <label htmlFor="mode1">


                                                    <svg width="30px"
                                                        height="30px"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fillRule="evenodd"
                                                        strokeLinejoin="round"
                                                        strokeMiterlimit={2}
                                                        clipRule="evenodd"
                                                        viewBox="0 0 64 64"
                                                    >
                                                        <path
                                                            fill="#ebad52"
                                                            d="M50.758,32.063L32.606,41.048C32.224,41.237 31.775,41.237 31.393,41.048L13.242,32.063C13.384,31.86 13.583,31.696 13.823,31.595L31.465,24.109C31.807,23.964 32.193,23.964 32.535,24.109L50.177,31.595C50.417,31.696 50.616,31.86 50.758,32.063Z"
                                                        />
                                                        <path
                                                            fill="#fed269"
                                                            d="M13.242,32.063L31.393,41.048C31.775,41.237 32.224,41.237 32.606,41.048L50.758,32.063C50.912,32.284 51,32.549 51,32.829L51,49.923C51,50.432 50.71,50.897 50.25,51.125L32.606,59.858C32.224,60.047 31.775,60.047 31.393,59.858L13.75,51.125C13.29,50.897 13,50.432 13,49.923L13,32.829C13,32.549 13.088,32.284 13.242,32.063Z"
                                                        />
                                                        <path
                                                            fill="#efedef"
                                                            d="M48,6C48,4.895 47.105,4 46,4L18,4C16.895,4 16,4.895 16,6L16,18C16,19.105 16.895,20 18,20L46,20C47.105,20 48,19.105 48,18L48,6Z"
                                                        />
                                                        <path
                                                            fill="#b27259"
                                                            d="M43.606,42.878C43.848,42.764 44,42.531 44,42.277L44,35.638C44,35.638 39.202,33.376 37.908,32.765C37.707,32.671 37.471,32.671 37.271,32.765C36.71,33.03 35.793,33.591 34.863,34.03C34.622,34.143 34.47,34.376 34.47,34.63C34.47,34.885 34.622,35.118 34.863,35.231L40,37.388L40,43.62C40,43.853 40.128,44.069 40.338,44.192C40.548,44.314 40.81,44.325 41.031,44.221C41.947,43.789 42.965,43.18 43.606,42.878Z"
                                                        />
                                                        <path
                                                            fill="#fa2d3c"
                                                            d="M26 7C26.552 7 27 7.448 27 8 27 8.552 26.552 9 26 9L24 9C23.448 9 23 9.448 23 10L23 14C23 14.552 23.448 15 24 15L26 15C26.552 15 27 15.448 27 16 27 16.552 26.552 17 26 17L24 17C22.343 17 21 15.657 21 14L21 10C21 8.343 22.343 7 24 7L26 7zM29 10C29 8.214 30.064 7.491 30.924 7.199 31.258 7.07 31.621 7 32 7 32.485 7 32.944 7.115 33.349 7.32 34.152 7.676 35 8.424 35 10L35 14C35 15.576 34.152 16.324 33.349 16.68 32.944 16.885 32.485 17 32 17 31.621 17 31.258 16.93 30.924 16.801 30.064 16.509 29 15.786 29 14L29 10zM33 14L33 10C33 9.574 32.734 9.21 32.359 9.066 32.164 9 32 9 32 9 32 9 31.836 9 31.642 9.066 31.266 9.21 31 9.574 31 10L31 14C31 14.426 31.266 14.79 31.642 14.934 31.836 15 32 15 32 15 32 15 32.164 15 32.359 14.934 32.734 14.79 33 14.426 33 14zM43 11.301L43 12.546C43 14.581 41.634 16.362 39.669 16.891L38.922 17.092C38.464 17.215 37.974 17.118 37.598 16.829 37.221 16.541 37 16.093 37 15.618L37 8.384C37 7.909 37.221 7.461 37.598 7.172 37.976 6.882 38.466 6.785 38.925 6.909L39.817 7.149C41.695 7.654 43 9.357 43 11.301zM39 15L39.15 14.96C40.241 14.666 41 13.676 41 12.546L41 11.301C41 10.261 40.302 9.35 39.298 9.08L39 9 39 15z"
                                                        />
                                                    </svg>

                                                    COD
                                                </label>
                                            </div>
                                            <div className="col-6 text-center">
                                                <input type="radio" checked={mode === "Stripe"} name="mode" value={'Stripe'} id="mode2" onChange={handleModeChange} />

                                                <label htmlFor="mode2">


                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30px"
                                                        height="30px" viewBox="0 0 28.87 28.87">
                                                        <g data-name="Layer 2">
                                                            <g data-name="Layer 1">
                                                                <rect width="28.87" height="28.87" fill="#6772e5" rx="6.48" ry="6.48" />
                                                                <path
                                                                    fill="#fff"
                                                                    fillRule="evenodd"
                                                                    d="M13.3 11.2c0-.69.57-1 1.49-1a9.84 9.84 0 0 1 4.37 1.13V7.24a11.6 11.6 0 0 0-4.36-.8c-3.56 0-5.94 1.86-5.94 5 0 4.86 6.68 4.07 6.68 6.17 0 .81-.71 1.07-1.68 1.07A11.06 11.06 0 0 1 9 17.25v4.19a12.19 12.19 0 0 0 4.8 1c3.65 0 6.17-1.8 6.17-5 .03-5.21-6.67-4.27-6.67-6.24z"
                                                                />
                                                            </g>
                                                        </g>
                                                    </svg>


                                                    Stripe
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                </div>



                                <div className="proced_payment">
                                    <button
                                        type="submit"
                                        className="btnsub  form-control  mt-3"
                                        style={{ width: 200, marginTop: "30px !important", marginLeft: 'auto' }}
                                        onClick={handleOrderSubmit}
                                    >
                                        Order Now
                                    </button>

                                </div>


                            </>)}



                        </>
                        )}


                    </div>



                </div>
                <div className="order_summary col-md-4">
                    <h1>Order Summary</h1>
                    <div className="summary_card">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Your Cart ({totalUniqueItems}) Total Items ({totalItems})</h5>

                            </div>
                            <div className="modal-body">



                                <div className="col-sm-12 col-md-12 col-md-offset-1">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th >Product</th>
                                                <th>Quantity</th>
                                                <th >Price</th>
                                                <th >Total</th>
                                                <th>Action </th>
                                            </tr>
                                        </thead>
                                        <tbody>




                                            {items.map((item) => (
                                                <tr key={item.id} >
                                                    <td className="col-sm-8 col-md-6" colspan="1">
                                                        <div className="media">

                                                            <img src={item.image} width={60} />

                                                        </div>
                                                    </td>

                                                    <td className="col-sm-1 col-md-1" style={{ textAlign: "center" }}>

                                                        <div className='d-flex gap-1 align-items-center'>
                                                            <button className="btn btn-primary btn-sm d-none" onClick={() => updateItemQuantity(item.id, item.quantity - 1)} >
                                                                -
                                                            </button>
                                                            <span>
                                                                {item.quantity}
                                                            </span>
                                                            <button className="btn btn-primary btn-sm d-none" onClick={() => updateItemQuantity(item.id, item.quantity + 1)} >
                                                                +
                                                            </button>
                                                        </div>

                                                        <hr />
                                                        <img src={item.overlayImage} width={'100px'} />


                                                    </td>
                                                    <td className="col-sm-1 col-md-1">
                                                        <strong>£{item.price}</strong>
                                                    </td>
                                                    <td className="col-sm-1 col-md-1">
                                                        <strong>£{item.quantity * item.price}</strong>
                                                    </td>
                                                    <td className="col-sm-1 col-md-1">
                                                        <button type="button" className="btn btn-danger" onClick={() => removeItem(item.id)}>
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width={20}
                                                                height={20}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#ffffff"
                                                                strokeWidth={2}
                                                                strokeLinecap="square"
                                                                strokeLinejoin="arcs"
                                                                style={{ margin: 0 }}

                                                            >
                                                                <polyline points="3 6 5 6 21 6" />
                                                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                                <line x1={10} y1={11} x2={10} y2={17} />
                                                                <line x1={14} y1={11} x2={14} y2={17} />
                                                            </svg>

                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}


                                            {isEmpty ? '' : (<tr>
                                                <td> </td>
                                                <td> </td>
                                                <td> </td>

                                                <td>
                                                    <h5>Total</h5>
                                                </td>
                                                <td className="text-right">
                                                    <h5>
                                                        <strong>£{cartTotal + items
                                                            .flatMap(item => item.mappedData.map(data => data.customtax)) // Extract all customtax values
                                                            .reduce((acc, curr) => acc + curr, 0)}</strong>
                                                    </h5>
                                                </td>
                                            </tr>)}






                                        </tbody>
                                    </table>

                                    <div class="text-center w-100">{isEmpty ? 'You cart Is empty' : ''}
                                    </div>

                                </div>


                            </div>



                        </div>

                    </div>
                </div>

                {OrderLoading && <>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "#00000096",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                        borderRadius: 10
                    }} >
                        <h4> Your Order is processing Please Wait ..</h4>
                    </div>

                </>}

            </div>




            <Footer />
        </>
    )
}

export default Checkout