import React, { useEffect } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useCart } from 'react-use-cart';

const Cancel = () => {
    const {
        emptyCart
    } = useCart();


    useEffect(() => {
        emptyCart();

    }, []);

    return (
        <>
            <Header />
            <div className="text-center container mt-5 mb-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#ff0000" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>                <br />  <br />
                <h1>Payment Unsccessful!</h1>

            </div>
            <Footer />

        </>
    )
}

export default Cancel