import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import toast from "react-hot-toast";
import axios from "axios";
import axiosInstance, { backurl } from "../../api/axiosInstance";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

    useEffect(() => {
        AOS.init({
            // Global settings:
            delay: 200, // values from 0 to 3000, with step 50ms
            duration: 1000, // values from 0 to 3000, with step 50ms
            easing: 'ease-in-out', // default easing for AOS animations
            once: true, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
        });
    }, []);


    const testimonials = [
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Neha Chauhan",
            "feedback": "You guys were really helpful in making our website look good, a very good company for web designing, have strong coding and gives an excellent after sale service.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Gurpreet Sidhu",
            "feedback": "Great service as always from Anil and the team!",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "zbrothers productions",
            "feedback": "Friendly service and amazing quality at a good price recommend to anyone.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Louisa",
            "feedback": "Anil and his team are always very helpful and provide good quality items with a really quick turnaround.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Yvette Elson",
            "feedback": "Anil is amazing. The help that he gives you and advice and service is fantastic. His turnaround for our personalised work-wear has been brilliant. We will definitely be using Brand Me Now in the future.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Ajay Kenth",
            "feedback": "Brand me now have been doing company uniforms for us over the past 8 years, we have always found their work to a good standard and they can't be beaten on price on like for like items, highly recommended.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "The Boparan Charitable Trust",
            "feedback": "We ordered 5 Cycling Jerseys from Brand Me Now. We needed the Jerseys turned around in a week. Other suppliers told us it would take 6 weeks or that they didn't have any stock. Anil and the team were the only ones who could provide the garments and the service all together - and they were brilliant. 5/5 for quality, 5/5 service.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Jamie Jones",
            "feedback": "I've used this company numerous times since they were recommended 5 years ago. Quality, choice, efficiency, and flexibility (Anil arranged for a batch of Aprons to be dropped off by a staff member outside of the usual contractual arrangements on one occasion) - I won't go anywhere else.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Bob Davies",
            "feedback": "Over the years have tried many companies with printed workwear and thought let's try this company. Excellent communication, tried samples then re-ordered Polos/Sweat/Fleece/trousers. Very pleased, look fabulous and feel good to wear which is what it's all about. Top company Top Man.",
            "date": "4 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Samantha Carter",
            "feedback": "The team at Brand Me Now went above and beyond to ensure our order was perfect. They provided great advice and the quality of the products was excellent. We will definitely be back!",
            "date": "3 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Harold Green",
            "feedback": "Quick turnaround and professional service. The uniforms look great and the pricing is competitive. Anil and his team did a fantastic job.",
            "date": "2 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Maria Johnson",
            "feedback": "The customer service was exceptional. We had a tight deadline, and they met it with time to spare. Highly recommend for anyone needing custom workwear.",
            "date": "1 day ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Liam Parker",
            "feedback": "Great quality workwear, exactly what we needed for our team. The process was smooth and the delivery was on time. We'll be ordering again soon.",
            "date": "5 days ago"
        },
        {
            "profile": "https://brandmenow-yzus.onrender.com/uploads/image-1723975318562.png",
            "name": "Emma Watson",
            "feedback": "From start to finish, the experience with Brand Me Now was top-notch. They helped us design the perfect uniforms for our staff and we couldn’t be happier with the outcome.",
            "date": "6 days ago"
        }
    ];

    const [layout, setHomeLayout] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [isProducts, setIsProducts] = useState(true);


    const [Products, setProducts] = useState([]);


    const getData = async () => {
        try {
            const { data } = await axiosInstance.get(`/home-layout-data`);
            setHomeLayout(data.homeLayout);
        } catch (error) {
            console.error(error);
            toast.error("Error fetching Home layout!");
        } finally {
            setIsLoading(false);
        }
    };


    const getProducts = async () => {
        try {
            const { data } = await axiosInstance.get("/all-home-products");
            console.log("products", data);
            setProducts(data.products);
            setIsProducts(false); // Set loading state to false in case of an error
        } catch (error) {
            console.error("Error fetching products:", error);
            setIsProducts(false); // Set loading state to false in case of an error
        }
    };


    useEffect(() => {
        getData();
        getProducts();
    }, []);


    return (

        <>
            <Header />



            {/* Hero Section */}
            <div className="hero hero-swiper " style={{ width: 1897, maxWidth: '100% ', height: 'auto', aspectRatio: "1519/715" }}>


                {isLoading ? (
                    <div className="skeleton w-100" style={{ width: 1897, maxWidth: '100% ', height: 'auto', aspectRatio: "1519/715" }}></div>
                ) : (
                    <Swiper pagination={true} navigation={true} gap={'30'} modules={[Pagination, Navigation]} className="swiper-wrapper">
                        {layout.home_slider && layout.home_slider.map((image, index) => (
                            <SwiperSlide key={index}>
                                <img className="img-fluid w-100" src={image} alt="Product" />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}

            </div>
            {/* Hero Section */}

            <section className=" px-4 bg-green" >
                <div className=" p-4">

                    <div className="d-flex my-new-cart gap-3 justify-content-between flex-sm-column">
                        <div
                            className="d-flex gap-2 text-dark text-decoration-none align-items-center" data-aos="fade-right"

                        >
                            <img src="/assets/images/Vectorshit.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">Quick Logo Preview</h6>
                                <p className="mb-0 fw-light">See logo on items.</p>
                            </div>
                        </div>

                        <div
                            className="d-flex gap-2 text-dark text-decoration-none align-items-center" data-aos="zoom-in"

                        >
                            <img src="/assets/images/TRUCK.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">Free Shipping</h6>
                                <p className="mb-0 fw-light">Orders over £200 ship free.</p>
                            </div>
                        </div>



                        <div
                            className="d-flex gap-2 text-dark text-decoration-none align-items-center" data-aos="zoom-in"

                        >
                            <img src="/assets/images/mockup-design%201.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">Free Digital Mockup Visual</h6>
                                <p className="mb-0 fw-light">Within 24 Hours</p>
                            </div>
                        </div>



                        <div
                            className="d-flex gap-2 text-dark text-decoration-none align-items-center" data-aos="fade-left"

                        >
                            <img src="/assets/images/Vectorshit.svg" alt="" width={30} />
                            <div className="d-block">
                                <h6 className="mb-0 fw-bold">No Minimums</h6>
                                <p className="mb-0 fw-light">Order 1 or 10,000, Welcome!</p>
                            </div>
                        </div>



                    </div>


                </div>


            </section>



            {/* Banner */}
            <div className="container py-4 py-lg-10">
                <div className="row">

                    {isLoading ? (<>
                        <div
                            className="col skeleton "
                            style={{ height: 380, borderRadius: 10 }}
                        ></div>
                    </>) : (layout.latest_product_banner && (
                        <>

                            <div className="col">
                                <div
                                    className="d-sm-flex justify-content-between align-items-center overflow-hidden  rounded-1"
                                    style={{ backgroundColor: "white" }}
                                >

                                    <div className="row px-4">

                                        <div className="col-md-8">
                                            <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                                                <h2 className="fw-light mb-2 d-sm-none" data-aos="fade-right">
                                                    <div>
                                                        BRAND ME <span className="fw-bold"> NOW </span>
                                                    </div>

                                                    <svg height="6" className="position-absolute ms-4 ps-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                                        <rect x="100" width="99.9998" height="6" fill="#636363" />
                                                        <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                                    </svg>

                                                </h2>


                                                <h3 className="fw-light mb-2 d-md-none" data-aos="fade-right">
                                                    <div>
                                                        BRAND ME <span className="fw-bold"> NOW </span>
                                                    </div>

                                                    <svg height="6" className="position-absolute" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                                        <rect x="100" width="99.9998" height="6" fill="#636363" />
                                                        <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                                    </svg>

                                                </h3>


                                                <h4 data-aos="fade-right" className="fs-base fw-light mt-4 mb-4">
                                                    PRINTED AND <span className="text-pink"> EMBROIDERED </span>  PERSONALISED CLOTHING


                                                </h4>

                                                <p data-aos="fade-right" className="fs-base fw-light mb-4 text-sm-start">
                                                    {layout.collection_paragraph || ''}
                                                </p>


                                                <Link data-aos="fade-right" to={layout.collection_url} className="pinkhead text-decoration-none text-white px-5 py-2 rounded d-inline-block fw-light">
                                                    Know More
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="col-md-3" data-aos="zoom-in">
                                            <img
                                                className="w-100 ms-auto"
                                                src={layout.collection_img || ''} width='auto'
                                                alt="Shop Converse"
                                            />
                                        </div>





                                    </div>

                                </div>
                            </div>
                        </>
                    )
                    )}



                </div>
            </div >
            {/* Banner */}



            <section>

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down" >
                                <div>
                                    <span className="fw-bold"> Top </span>  Bundles
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                        </div>
                    </div>



                    {isProducts ? (Array.from({ length: 7 }).map((_, index) => (
                        <div className="row g-3 py-0 m-0" key={index} >

                            <div
                                className="card-1 skeleton"
                                style={{ height: 371, borderRadius: 10 }}
                            ></div>

                        </div>
                    ))
                    ) : (layout.best_selling_laptop && (
                        <>
                            <div className="row g-3 py-0 m-0" >

                                {Products.map((product, index) => {


                                    return (

                                        layout.best_selling_laptop.includes(product._id) && (<>


                                            <div className="col-md-3 col-12 m-0" data-aos="zoom-in" key={index} >


                                                <div className="card1 mb-4 w-100 pb-4" style={{ height: "auto" }} >
                                                    <Link classname="price" to={`/product/${product._id}`}>

                                                        <figure>
                                                            <img src={`${product.pImage}`} alt="t-shirt" width={'100%'} />
                                                        </figure>

                                                    </Link>

                                                    <h4 className="text-center px-2 fw-light"> {product.title}</h4>


                                                    <div className="d-flex px-2 gap-2">
                                                        <div className="col text-end">
                                                            <Link className="price text-decoration-none text-pink"
                                                                to={`/product/${product._id}`}><del className="fw-light text-dark">£{product.regularPrice}</del> <span className="text-pink"> £{product.salePrice}  </span></Link>
                                                        </div>
                                                        <div className="col text-start">
                                                            <Link to={`/product/${product._id}`} style={{ borderRadius: "0px" }} className="btn bg-mygreen text-white border-0">



                                                                Shop Now
                                                            </Link>
                                                        </div>
                                                    </div>




                                                </div>


                                            </div>


                                            {/* Product Cards */}



                                        </>)


                                    )
                                }
                                )}

                            </div >


                        </>
                    )
                    )}


                    <div className="col-6">

                    </div>

                    <div className="col-6">

                    </div>

                </div>
            </section>


            <section className="bg-light">

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down"  >
                                <div>
                                    Order in    <span className="fw-bold"> 3 Simple  </span>  Steps
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                            <p className="text-center fw-light h5">
                                Choose your product, personalise it, and complete your purchase in just three simple steps:<br />
                                “Find Product, Customise & Checkout”
                            </p>

                        </div>
                    </div>



                    <div className="d-mob-block d-flex ">
                        <div className="col-md-4 " data-aos="zoom-in"  >
                            <img src="/assets/images/step1.svg" className="w-100" />
                        </div>
                        <div className="col-md-4" data-aos="zoom-in"  >
                            <img src="/assets/images/step2.svg" className="w-100" />
                        </div>
                        <div className="col-md-4" data-aos="zoom-in"  >
                            <img src="/assets/images/step3.svg" className="w-100" />
                        </div>
                    </div>

                    <div className="mb-4" />

                </div>
            </section>


            <section className="bg-dark" style={{
                backgroundImage: "url('/assets/images/Group%2072.png')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover"
            }} >

                <div className="row px-4 container ">

                    <div className="col-md-12 ">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center  " style={{ color: "#bfd131" }} data-aos="fade-down"
                            >
                                <div>
                                    <span className="fw-bold "> Popular   </span>  Items
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                        </div>
                    </div>


                    {isLoading ? (<>
                        <div
                            className="col-md-6 skeleton "
                            style={{ height: 380, borderRadius: 10 }}
                        ></div>  <div
                            className="col-md-6 skeleton "
                            style={{ height: 380, borderRadius: 10 }}
                        ></div>
                    </>) : (layout.trending_product_banner && (
                        <>

                            {layout.trending_product_banner.map((banner, index) => (

                                <Link data-aos="zoom-in" class="col-md-4 mb-4" key={index} to={banner.imageUrlInput} >
                                    <img src={banner.imageInput} className="w-100 rounded-xl" style={{ borderRadius: 25 }} alt="" />
                                </Link>
                            ))}
                        </>
                    )
                    )}



                    <div className="mb-4" />

                </div>
            </section>








            <section className="bg-white">

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down"  >
                                <div>
                                    Trending    <span className="fw-bold"> Products </span>
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                            {isProducts ? (Array.from({ length: 7 }).map((_, index) => (
                                <div className="row g-3 py-0" key={index} >

                                    <div
                                        className="card-1 skeleton"
                                        style={{ height: 371, borderRadius: 10 }}
                                    ></div>

                                </div>
                            ))
                            ) : (layout.trending_product && (
                                <>
                                    <div className="row g-3 py-0">

                                        {Products.map((product, index) => {


                                            return (

                                                layout.trending_product.includes(product._id) && (<>


                                                    <div className="col-md-3" data-aos="zoom-in" key={index} >


                                                        <div className="card1 mt-4" style={{ height: '100%' }}>
                                                            <div classname="price m-0" >

                                                                <img src={`${product.pImage}`} alt="t-shirt" width={'100%'} style={{ aspectRatio: "1/0.7", objectFit: "cover", objectPosition: "top" }}
                                                                />

                                                                <Link to={`/product/${product._id}`} className="bg-dark d-block text-decoration-none text-white text-center py-2">
                                                                    SHOP NOW
                                                                </Link>
                                                                <h4 className="fw-medium h6 text-center mt-2">  {product.title} </h4>
                                                                <div className="col text-center mb-0">
                                                                    <Link className="price text-decoration-none text-pink"
                                                                        to={`/product/${product._id}`}><del className="fw-light text-dark">£{product.regularPrice}</del> <span className="text-pink"> £{product.salePrice}  </span></Link>
                                                                </div>
                                                            </div>



                                                        </div>


                                                    </div>


                                                    {/* Product Cards */}



                                                </>)


                                            )
                                        }
                                        )}

                                    </div >


                                </>
                            )
                            )}



                        </div>
                    </div>





                    <div className="mb-4" />

                </div>
            </section>



            <section className="bg-white">

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down"  >
                                <div>
                                    What Our <span className="fw-bold"> Customers  </span> Say About Us
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>


                            <div className="row mt-4 pt-2 mx-n2 hero-swiper px-3">
                                <Swiper breakpoints={{
                                    300: {
                                        slidesPerView: 1,
                                        spaceBetween: 10, // Set the gap between slides for window width <= 400px
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 15, // Set the gap between slides for window width <= 768px
                                    },
                                    992: {
                                        slidesPerView: 4,
                                        spaceBetween: 20, // Set the gap between slides for window width <= 992px
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                        spaceBetween: 20, // Set the gap between slides for window width <= 1200px
                                    },
                                }}
                                    autoplay={true} navigation={true} pagination={false} modules={[Pagination, Navigation, Autoplay]} className="swiper-wrapper" >


                                    {isLoading ? (Array.from({ length: 7 }).map((_, index) => (
                                        <SwiperSlide key={index} >
                                            <div
                                                className="card-1 skeleton"
                                                style={{ height: 355, borderRadius: 10 }}
                                            ></div>

                                        </SwiperSlide>
                                    ))
                                    ) : (
                                        testimonials.map((testimonial, index) => (
                                            <SwiperSlide key={index} className="mylogos">
                                                <div className="single-testimonial border-0 bg-white">
                                                    <div className="client-details d-flex gap-2 ">
                                                        <div className="profile border-0 m-0">
                                                            <img width={50} src={testimonial.profile} className="border-0 m-0" alt="profile" />
                                                        </div>

                                                        <div className="testi-content m-0">
                                                            <h6 className="m-0" style={{
                                                                display: "-webkit-box",
                                                                WebkitLineClamp: 1,
                                                                WebkitBoxOrient: "vertical",
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                            }} >{testimonial.name}</h6>
                                                            <div className="d-flex gap-2 justify-content-between">
                                                                <div className="d-flex">
                                                                    {[...Array(4)].map((_, i) => (
                                                                        <i className="ri-star-fill" style={{ color: "#ee6c06" }} key={i}></i>
                                                                    ))}
                                                                </div>
                                                                <p className="date m-0 mb-1">{testimonial.date}</p>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* 
                                                    <div>
                                                        <span>
                                                            <i className="ri-verified-badge-fill me-1"></i>
                                                            Verified Customer
                                                        </span>
                                                    </div> */}
                                                    <div className="round-2 round" />
                                                    <p className="overflowCustomers">
                                                        {testimonial.feedback}
                                                    </p>
                                                    {/* <p className="footer-fix">{testimonial.date}</p> */}
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    )}

                                </Swiper>

                            </div>


                        </div>

                    </div>
                </div>
            </section>






            <section className="bg-white">

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down"  >
                                <div>
                                    A Selection Of <span className="fw-bold"> Customers  </span> That Put Their Trust In Us
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                            <div className="row mt-4 pt-2 mx-n2 hero-swiper ">
                                <Swiper breakpoints={{
                                    300: {
                                        slidesPerView: 4,
                                        spaceBetween: 10, // Set the gap between slides for window width <= 400px
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 20, // Set the gap between slides for window width <= 768px
                                    },
                                    992: {
                                        slidesPerView: 6,
                                        spaceBetween: 30, // Set the gap between slides for window width <= 992px
                                    },
                                    1200: {
                                        slidesPerView: 6,
                                        spaceBetween: 40, // Set the gap between slides for window width <= 1200px
                                    },
                                }}
                                    autoplay={true} navigation={false} pagination={false} modules={[Pagination, Navigation, Autoplay]} className="swiper-wrapper" >


                                    {isLoading ? (Array.from({ length: 7 }).map((_, index) => (
                                        <SwiperSlide key={index} data-aos="zoom-in"  >
                                            <div
                                                className="card-1 skeleton"
                                                style={{ height: 355, borderRadius: 10 }}
                                            ></div>

                                        </SwiperSlide>
                                    ))
                                    ) : (layout.latest_product_banner && (
                                        <>

                                            {layout.latest_product_banner.map((carousal, index) => (

                                                <SwiperSlide key={index} className="mylogos"  >
                                                    <Link
                                                        to={carousal.imageUrlInput}
                                                    >
                                                        <img src={carousal.imageInput} className="w-100 rounded" style={{
                                                            height: "auto",
                                                            aspectRatio: "1 / 1",
                                                            objectFit: "contain",
                                                            padding: 10
                                                        }} />
                                                    </Link>

                                                </SwiperSlide>

                                            ))}
                                        </>
                                    )
                                    )}



                                </Swiper>

                            </div>


                        </div>
                    </div>
                </div>
            </section>




            <section className="bg-white">

                <div className="row px-4 container">

                    <div className="col-md-12">
                        <div className="py-4 my-2 my-md-0 py-md-5 px-4 ms-md-3 text-center text-sm-start">


                            <h3 className="fw-light mb-0 h1 text-center" data-aos="fade-down"  >
                                <div>
                                    <span className="fw-bold"> Top
                                    </span> Brands
                                </div>

                                <svg height="6" style={{ marginTop: "-29px" }}
                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 2C0 0.89543 0.895431 0 2 0H99.9998V6H2C0.895431 6 0 5.10457 0 4V2Z" fill="#BFD131" />
                                    <rect x="100" width="99.9998" height="6" fill="#636363" />
                                    <path d="M200 0H298C299.104 0 300 0.895431 300 2V4C300 5.10457 299.104 6 298 6H200V0Z" fill="#BE2A80" />
                                </svg>

                            </h3>

                            <div className="row mt-4 pt-2 mx-n2 hero-swiper ">
                                <Swiper breakpoints={{
                                    300: {
                                        slidesPerView: 4,
                                        spaceBetween: 10, // Set the gap between slides for window width <= 400px
                                    },
                                    768: {
                                        slidesPerView: 4,
                                        spaceBetween: 20, // Set the gap between slides for window width <= 768px
                                    },
                                    992: {
                                        slidesPerView: 6,
                                        spaceBetween: 30, // Set the gap between slides for window width <= 992px
                                    },
                                    1200: {
                                        slidesPerView: 6,
                                        spaceBetween: 40, // Set the gap between slides for window width <= 1200px
                                    },
                                }}
                                    autoplay={true} navigation={false} pagination={false} modules={[Pagination, Navigation, Autoplay]} className="swiper-wrapper" >


                                    {isLoading ? (Array.from({ length: 7 }).map((_, index) => (
                                        <SwiperSlide key={index} data-aos="zoom-in"  >
                                            <div
                                                className="card-1 skeleton"
                                                style={{ height: 355, borderRadius: 10 }}
                                            ></div>

                                        </SwiperSlide>
                                    ))
                                    ) : (layout.trending_product_carousal && (
                                        <>

                                            {layout.trending_product_carousal.map((carousal, index) => (

                                                <SwiperSlide key={index} className="mylogos"  >
                                                    <Link
                                                        to={carousal.imageUrlInput}
                                                    >
                                                        <img src={carousal.imageInput} className="w-100 rounded border-0" style={{
                                                            height: "auto",
                                                            aspectRatio: "1 / 1",
                                                            objectFit: "contain",
                                                            padding: 10
                                                        }} />
                                                    </Link>

                                                </SwiperSlide>

                                            ))}
                                        </>
                                    )
                                    )}



                                </Swiper>

                            </div>


                        </div>
                    </div>
                </div>
            </section>






            {/* <section className="py-0 section-bubble4">
                <section className="about">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-md-3" />
                            <div className="col-md-3" />
                            <div className="col-md-6 mt-5">
                                <h3>BRAND ME NOW</h3>
                                <p>shop here a best quality of clothing</p>
                                <button className="btn2" fdprocessedid="0arnq">
                                    {" "}
                                    SHOP NOW
                                </button>
                            </div>
                        </div>
                    </div>
                </section>

            </section> */}

            <Footer />

        </>
    )
}

export default Home