import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import toast from "react-hot-toast";
import { authActions } from "../../redux/store";


const AccountSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //logout
    const handleLogout = () => {
        try {
            dispatch(authActions.logout());
            toast.success("Logout Successfully");
            navigate("/login");
            // localStorage.clear();
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('user');


        } catch (error) {
            console.log(error);
        }
    };


    return (
        <>
            <div className="overlay col-md-3 mt-1" id="left">

                <Link to="/account/" class="mb-3">
                    <button className="btn2 mt-2 form-control" fdprocessedid="b0h82a">
                        <span>Dashboard</span>
                    </button>
                </Link>


                <Link to="/account/orders" class="mb-3">
                    <button className="btn2 mt-2 form-control" fdprocessedid="b0h82a">
                        <span>My orders</span>
                    </button>
                </Link>

                <Link to="/account/profile" class="mb-3">
                    <button className="btn2 mt-2 form-control" fdprocessedid="b0h82a">
                        <span>My profile</span>
                    </button>
                </Link>

                <Link to="/account/password" class="mb-3">
                    <button className="btn2 mt-2 form-control" fdprocessedid="b0h82a">
                        <span> Password</span>
                    </button>
                </Link>

                <Link onClick={handleLogout} class="mb-3">
                    <button className="btn2 mt-2 form-control" fdprocessedid="b0h82a">
                        <span> Logout</span>
                    </button>
                </Link>

            </div>

        </>
    )
}

export default AccountSidebar