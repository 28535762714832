import React, { useState, useEffect } from 'react';
import AccountSidebar from '../components/AccountSidebar';

import { toast } from 'react-hot-toast';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import axiosInstance from '../../api/axiosInstance';


const AccountPassword = () => {



    const [loading, setLoading] = useState(true); // Add loading state
    const [SubmitLoading, setSubmitLoading] = useState(true); // Add loading state

    const [formData, setFormData] = useState({
        password: '',
        password2: '',
    });



    const submitData = async () => {
        const id = localStorage.getItem('userId');
        if (formData.password === formData.password2) {
            setSubmitLoading(false);

            try {
                if (id) {
                    await axiosInstance.put(`/update-profile/${id}`, formData);
                    toast.success('password Updated!');
                    window.location.reload(); // Reload the page

                }
            } catch (error) {
                console.error('Error On Profile:', error);
                console.log(formData);
                toast.error(error.response.data.message);
            } finally {
                setSubmitLoading(true);
            }
        }
        else {
            toast.error("Password not Match");

        }

    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: type === 'checkbox' ? checked : value }));
    };



    return (
        <>
            <Header />
            <section id="mid">
                <div className="container mt-3">
                    <div className="row">
                        <AccountSidebar />
                        <div className="col-md-9">
                            <h2>Password Management</h2>

                            <div className="details_card">
                                <div className=" row p-2">


                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <h4>New Password</h4>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password1"
                                                name="password"
                                                placeholder="Enter Password"
                                                value={formData.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <h4>Re-enter Password</h4>

                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password2"
                                                name="password2"
                                                placeholder="Enter Password"
                                                value={formData.password2}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                </div>

                                <div className='overlay '>


                                    {SubmitLoading ? (
                                        <button
                                            type="button"
                                            id="submit"
                                            name="submit"
                                            className="btn2 mx-auto mt-3"
                                            onClick={submitData} >
                                            <span>Update Password</span>
                                        </button>

                                    ) : (

                                        <button className="btn2 mx-auto mt-3" type="button" disabled>
                                            <span class="ms-1">Loading...</span>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </button>

                                    )}


                                </div>




                            </div>

                        </div>
                    </div>
                </div>
            </section >

            <Footer />
        </>
    )
}

export default AccountPassword