import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from "axios";
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useCart } from "react-use-cart";
import toast from "react-hot-toast";

import front_image from "../assets/images/front_image.jpg";
import back_image from "../assets/images/back_image.jpg";
import left_side_image from "../assets/images/left_side_image.jpg";
import right_side_image from "../assets/images/right_side_image.jpg";
import inside_neck_image from "../assets/images/inside_neck_image.jpg";
import axiosInstance from '../../api/axiosInstance';

const ProductCollection = () => {

    const [loadingUpload, setUploadLoading] = useState(false);


    const { addItem, updateItemQuantity, items } = useCart();
    const [selectedColor, setSelectedColor] = useState(''); // State to hold the selected color

    const [SelectedSizes, setSelectedSizes] = useState({});
    const [TotalQuantity, setTotalQuantity] = useState({});



    const [sections, setSections] = useState([]);
    const [mappedData, setmappedData] = useState([]);



    const handleSecInputChange = (index, name, value) => {
        const updatedSections = [...sections];
        updatedSections[index][name] = value;
        setSections(updatedSections);

        const mappedData = updatedSections.map(section => {
            const { inputValue, imageUrl, Artwork, CustomName, CustomWidth, CustomiseTyp, PrintTyp, image, width } = section;
            return { inputValue, imageUrl, Artwork, CustomName, CustomWidth, CustomiseTyp, PrintTyp, image, width };
        });

        setmappedData(mappedData)
        console.log('mappedData', mappedData);

    };



    // const handleDownloadAsPdf = (product) => {
    //     const element = document.getElementById('overlay-container');

    //     const pdf = new jsPDF();
    //     const elementWidth = element.offsetWidth; // Get the width of the HTML content

    //     const pdfWidth = pdf.internal.pageSize.getWidth(); // Get the width of the PDF document
    //     const pdfHeight = pdf.internal.pageSize.getHeight(); // Get the height of the PDF document

    //     const scale = pdfWidth / elementWidth; // Calculate the scale factor

    //     pdf.html(element, {
    //         html2canvas: {
    //             scale: scale, // Apply the calculated scale
    //             width: elementWidth,
    //             windowWidth: elementWidth,
    //             windowHeight: pdfHeight,
    //             useCORS: true
    //         },
    //         callback: () => {
    //             // pdf.save('inner_html_content.pdf');
    //             const base64String = pdf.output('datauristring');
    //             setPdfBase64(base64String);
    //             handleAddToCart(product, base64String)
    //         }
    //     });
    // };

    const handleAddToCart = (product, base64String) => {


        console.log(base64String)
        if (!TotalQuantity) {
            toast.error('Please select a Quantity');
        } else {

            const existingProduct = items.find((item) => item.id === product.id && item.color === selectedColor);

            if (existingProduct) {
                // const updatedQuantity = existingProduct.quantity + 1;
                const updatedQuantity = TotalQuantity;

                updateItemQuantity(existingProduct.id, updatedQuantity);
                toast.success('Product quantity updated in cart');

            } else {
                addItem({ ...product, color: selectedColor, customise: base64String }, TotalQuantity);
                toast.success('Product Added To Cart');
            }
        }

    };


    const [frontImage, setFrontImage] = useState(null);
    const [overlayImage, setOverlayImage] = useState(null);
    const overlayRef = useRef(null);
    const [overlayPosition, setOverlayPosition] = useState({ x: 0, y: 0 });
    const [overlaySize, setOverlaySize] = useState({ width: 200, height: 200 });
    const [isResizing, setIsResizing] = useState(false);
    const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
    const [resizeStartSize, setResizeStartSize] = useState({ width: 0, height: 0 });


    const [text, setText] = useState('');
    const [textPosition, setTextPosition] = useState({ x: 10, y: 10 });
    const [isTextDragging, setIsTextDragging] = useState(false);
    const [textColor, setTextColor] = useState('#000000');
    const [textSize, setTextSize] = useState(16);



    const [loading, setLoading] = useState(true); // Add loading state
    const [Image, setImage] = useState('');

    const [mainImage, setMainImage] = useState('');

    const [Product, setProduct] = useState({});
    const [Attr, setAttr] = useState([]);
    const [sizeQuantities, setSizeQuantities] = useState({});
    const [variations, setVariations] = useState([]);
    const [Images, SetImages] = useState([]);

    const [variationsSize, setVariationsSize] = useState([]);

    const [Varloading, SetVarLoading] = useState(true); // Add loading state

    const { id, storeid } = useParams();

    const handleSmallImageClick = (newImageSrc) => {
        setFrontImage(newImageSrc);
    };

    const getCategory = async () => {
        try {
            const { data } = await axiosInstance.get(`/user-private-product/${storeid}/${id}`);
            setProduct(data.Product);
            setVariations(data.Product.variations.Color);
            setVariationsSize(data.Product.variations.Size);
            SetImages(data.Product.images)
            setFrontImage(data.Product.pImage)
            console.log('data pro', data.Product)

            // Set the formData.username based on the blog's slug
        } catch {

        }
        finally {
            setLoading(false); // Set loading to false when data is fetched
            SetVarLoading(false);
            console.log('Image', Image)
        }
    }

    const getAttribute = async () => {
        try {
            const { data } = await axiosInstance.get('/all-attribute');
            console.log('attr', data);
            setAttr(data.Attribute);
        } catch (error) {
            console.error('Error fetching attributes:', error);
        }
    };



    const handleIncrement = (size, maxLength) => {

        const currentValue = sizeQuantities[size] || 0;

        setSizeQuantities(prevQuantities => ({
            ...prevQuantities,
            [size]: currentValue + 1
        }));


    };


    // Function to handle decrement for a particular size
    const handleDecrement = (size) => {
        if (sizeQuantities[size] > 0) {
            setSizeQuantities(prevQuantities => ({
                ...prevQuantities,
                [size]: prevQuantities[size] - 1
            }));
        }
    };

    // Function to handle input change for a particular size
    const handleInputChange = (size, event) => {
        const value = parseInt(event.target.value, 10) || 0;
        setSizeQuantities(prevQuantities => ({
            ...prevQuantities,
            [size]: value
        }));
    };

    // Calculate selected sizes and total quantity when sizeQuantities change
    useEffect(() => {
        const selected = Object.entries(sizeQuantities).filter(([_, quantity]) => quantity > 0);
        const totalQuantity = selected.reduce((acc, [_, quantity]) => acc + quantity, 0);
        setSelectedSizes(Object.fromEntries(selected));
        setTotalQuantity(totalQuantity)
        console.log('Selected Sizes:', Object.fromEntries(selected));
        console.log('Total Quantity:', totalQuantity);
    }, [sizeQuantities]);

    useEffect(() => {
        getCategory();
        getAttribute();
    }, []);



    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleTextDrag = (e, data) => {
        setTextPosition({ x: data.x, y: data.y });
    };


    const handleTextResize = (e) => {
        setTextSize(parseInt(e.target.value, 10));
    };

    const handleTextColorChange = (e) => {
        setTextColor(e.target.value);
    };

    const handleTextMouseDown = () => {
        setIsTextDragging(true);
    };

    const handleTextMouseUp = () => {
        setIsTextDragging(false);
    };


    const handleAddText = () => {
        setText('Add Text Here');
    };

    const handleDeleteText = () => {
        setText('');
    };





    const handleOverlayImageUpload = (e) => {
        const overlayFile = e.target.files[0];

        if (overlayFile) {
            // Check file size
            const fileSizeInKB = overlayFile.size / 1024; // Convert bytes to kilobytes
            const maxSizeKB = 500; // Maximum file size allowed in kilobytes (500KB in this example)

            if (fileSizeInKB > maxSizeKB) {
                // Show alert if file size exceeds the limit
                alert('File size exceeds the limit (500KB). Please choose a smaller file.');
                return; // Exit function if file size is too large
            }
            else {

                const reader = new FileReader();

                reader.onload = (event) => {
                    const overlayImg = new window.Image();
                    overlayImg.onload = () => {
                        setOverlayImage(overlayImg);
                    };
                    overlayImg.src = event.target.result;
                };

                reader.readAsDataURL(overlayFile);
            }

        }


    };

    const handleMouseDown = (e, position) => {
        setOverlayPosition(position);
    };

    const handleMouseMove = (e) => {
        if (isResizing && overlayRef.current) {
            const deltaX = e.clientX - resizeStart.x;
            const deltaY = e.clientY - resizeStart.y;

            const newWidth = Math.max(20, resizeStartSize.width + deltaX);
            const newHeight = Math.max(20, resizeStartSize.height + deltaY);

            setOverlaySize({ width: newWidth, height: newHeight });
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    const handleResizeMouseDown = (e) => {
        setIsResizing(true);
        setResizeStart({ x: e.clientX, y: e.clientY });
        setResizeStartSize({ width: overlaySize.width, height: overlaySize.height });
    };

    const isCustomiseTypMatched = (customiseTyp) => {
        return mappedData.some(item => item.CustomiseTyp === customiseTyp);
    };

    return (

        <>
            <Header />

            {loadingUpload && <div className="loadingbox">

                <span>
                    {" "}
                    <i className="fa fa-circle-o-notch fa-spin" style={{ fontSize: 24 }} />
                    Loading...
                </span>

            </div>}


            <section className=" container sproduct my-4 pt-5 ">
                <div className="row mt-3 ">

                    <div className="col-lg-5 col-md-12 col-12">

                        <div style={{ position: 'relative' }} id="overlay-container" bounds="parent">

                            {frontImage && <img src={frontImage} className="img-fluid w-100 pb-2"
                                alt="Front Image" style={{ width: '100%' }} />}


                            <div style={{ position: 'absolute', left: textPosition.x, top: textPosition.y, zIndex: '3' }}>
                                {text && (
                                    <Draggable onDrag={handleTextDrag} >
                                        <div
                                            style={{
                                                color: textColor,
                                                fontSize: `${textSize}px`,
                                                cursor: 'move',
                                                userSelect: 'none',
                                            }}
                                        >
                                            {text}
                                        </div>
                                    </Draggable>
                                )}
                            </div>

                            {/* 
                            {overlayImage && (
                                <Draggable
                                    position={overlayPosition}
                                    onStart={(e, data) => handleMouseDown(e, data)}
                                    onDrag={handleMouseMove}
                                    onStop={handleMouseUp}
                                    bounds="parent"

                                >
                                    <div
                                        style={{
                                            position: 'absolute',
                                            width: overlaySize.width,
                                            height: overlaySize.height,
                                            cursor: 'move',
                                            top: 0,
                                        }}

                                        className='overlayRef-box'
                                        ref={overlayRef}
                                    >


                                        <img
                                            src={overlayImage.src}
                                            alt="Overlay Image"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                        <div
                                            className='resize-elemet'

                                            onMouseDown={handleResizeMouseDown}

                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" style={{ margin: '0px', }} width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#1d1d1d" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs"><path d="M8 3v3a2 2 0 0 1-2 2H3m18 0h-3a2 2 0 0 1-2-2V3m0 18v-3a2 2 0 0 1 2-2h3M3 16h3a2 2 0 0 1 2 2v3"></path></svg>
                                        </div>
                                    </div>
                                </Draggable>
                            )} */}

                        </div>


                        <div className="small-img-group">
                            <div className="small-img-col">

                                {Images.map((imageUrl, index) => (
                                    <img key={index} src={imageUrl} width="100%" onClick={() => handleSmallImageClick(imageUrl)}
                                        className="small-img" alt="" />
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 left">
                        <h6>Home / T-Shirts </h6>
                        <h3>{Product.title}</h3>
                        <h2><del>${Product.regularPrice}</del> ${Product.salePrice}</h2>


                        <strong>Choose Size:</strong>
                        <div class="size_threebtn">
                            <div class="col-lg-12 col-md-12 col-12 m ">


                                {Varloading ? (
                                    <div className="loader">Loading...</div>
                                ) : (
                                    <>



                                        {variationsSize && variationsSize.length > 0 ? (
                                            variationsSize.map((sizeInfo, index) => (

                                                <div class="size" key={index} >
                                                    <div >
                                                        <span class="spantext" >
                                                            {sizeInfo.Size}
                                                        </span>                                                    </div>

                                                    <div>
                                                        <button onClick={() => handleDecrement(sizeInfo.Size, sizeInfo.stock)}>-</button>
                                                        <input type="text" value={sizeQuantities[sizeInfo.Size] || 0} readOnly className="border-0 bg-transparent"
                                                            maxLength={parseInt(sizeInfo.stock, 10) || 2} // Ensure sizeInfo.stock is a number
                                                        />
                                                        <button onClick={() => handleIncrement(sizeInfo.Size, sizeInfo.stock)}>+</button>
                                                    </div>

                                                    <span class="spantext">{sizeInfo.stock} in Stock</span>

                                                </div>



                                            ))
                                        ) : (
                                            <p>No size Found</p>
                                        )}

                                    </>
                                )}



                            </div>

                            <div class="overlay space col-lg-10 col-md-12 col-12">


                                {/* 
                                <button onClick={handleAddText} >
                                    <span>Add Text</span>
                                </button> */}
                            </div>
                            {/* <button onClick={handleDownloadAsPdf}>Download as PDF</button> */}


                            {/* <div class="overlay space col-lg-10 col-md-12 col-12">
                                <button id="upload"><span>Upload File</span>
                                    <label class="file-upload-btn">
                                        <input type="file" accept="image/*" onChange={handleOverlayImageUpload} />

                                    </label>
                                </button>
                            </div> */}




                            <div className="overlay space col-lg-10 col-md-12 col-12  w-100">



                                {/* <button onClick={() => addSection()}    >
                                    <span>Customisation</span>
                                </button> */}

                                <button onClick={() => {

                                    handleAddToCart({ id: Product._id, title: Product.title, image: frontImage, regularPrice: Product.regularPrice, price: Product.salePrice, color: selectedColor, TotalQuantity: TotalQuantity, SelectedSizes: SelectedSizes, mappedData: mappedData });
                                }}>
                                    <span>Add To Cart</span>
                                </button>



                                {/* <button onClick={handleAddText} >
                                    <span>Add Text</span>
                                </button> */}
                            </div>

                        </div>



                    </div>
                </div>

            </section >


            <section className="container  mt-9 py-3">
                <div className="row mt-2 srow">
                    <div className="col-lg-6 col-md-12 col-12">
                        <h2>Product Details</h2>
                        <div className="col-md-12 col-12" dangerouslySetInnerHTML={{ __html: Product.description }} />

                    </div>

                </div>


            </section>

            <Footer />
        </>
    )
}

export default ProductCollection