// BlogContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import axiosInstance from '../api/axiosInstance';
const BlogContext = createContext();

export const BlogProvider = ({ children }) => {
    const [blogs, setBlogs] = useState([]);
    const [userBlog, setUserBlogs] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [Headers, setHeaders] = useState([]);

    const [isHeader, setIsHeader] = useState(true);

    const [AllProducts, setAllProducts] = useState([]);
    const [AllCategoriess, setAllCategoriess] = useState([]);
    const [embroidery, setEmbroidery] = useState([]);
    const [print, setPrint] = useState([]);

    const getAllBlogs = async () => {
        // try {
        //     const response = await axiosInstance.get('/all-blogs');
        //     const { success, blogs, message } = response.data;
        //     console.log(response.data)
        //     if (success) {
        //         setBlogs(blogs);
        //     } else {
        //         console.error('Error fetching blogs:', message);
        //     }
        // } catch (error) {
        //     console.error('Error fetching blogs:', error);
        // } finally {
        //     setIsLoading(false);
        // }


    };




    const getUserAllPro = async () => {
        try {
            const response = await axiosInstance.get(`/all-products/`);

            setAllProducts(response.data.products);

        } catch (error) {
            console.error('Error fetching user products:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const getUserAllCat = async () => {
        try {
            const response = await axiosInstance.get(`/all-category/`);

            setAllCategoriess(response.data.categories);
        } catch (error) {
            console.error('Error fetching user category:', error);
        } finally {
            setIsLoading(false);
        }
    };





    const getHeader = async () => {
        try {

            const { data } = await axiosInstance.get(`/home-data`);
            setHeaders(data.homeData);
            console.log('data', data.homeData);
            setPrint(data.homeData.print);
            setEmbroidery(data.homeData.embroidery);
            setIsHeader(false);
        }
        catch (error) {
            console.log(error);
            setIsHeader(false);
        }
    };



    const getHomeData = async () => {
        try {

            const { data } = await axiosInstance.get(`/home-layout-data`);
            setHeaders(data.homeData);
            console.log('data', data.homeData);

            setIsHeader(false);
        }
        catch (error) {
            console.log(error);
            setIsHeader(false);
        }
    };

    const getUserBlogs = async () => {
        // try {
        //     const userId = localStorage.getItem('userId');
        //     const response = await axiosInstance.get(`http://localhost:8000/user-blogs/${userId}`);
        //     const { success, userBlog, message } = response.data;
        //     if (success) {
        //         setUserBlogs(userBlog.blogs);
        //     } else {
        //     }
        // } catch (error) {
        //     console.error('Error fetching user blogs:', error);
        // } finally {
        //     setIsLoading(false);
        // }
    };


    useEffect(() => {
        getAllBlogs();
        getUserBlogs();
        getHeader();
        getUserAllPro();
        getUserAllCat();
    }, []);

    return (
        <BlogContext.Provider value={{
            blogs, userBlog, isLoading, Headers, isHeader, AllCategoriess, AllProducts, embroidery, print
        }}>
            {children}
        </BlogContext.Provider>
    );
};

export const useBlogContext = () => {
    const context = useContext(BlogContext);
    if (!context) {
        throw new Error('useBlogContext must be used within a BlogProvider');
    }
    return context;
};
