import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import axiosInstance from '../../api/axiosInstance';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
    const [isVerified, setIsVerified] = useState(false);

    const handleVerification = (response) => {
        console.log('reCAPTCHA verification successful:', response);
        setIsVerified(true);
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        if (!isVerified) {
            toast.error('Please complete the reCAPTCHA verification.');

            return;
        }
        else {
            try {
                await axiosInstance.post('/contact-enquire', formData);
                toast.success('Email sent successfully');
                setFormData({ name: '', email: '', message: '' }); // Clear form fields
            } catch (error) {
                console.error('Failed to send email:', error);
                toast.error('Failed to send email');
            }
        }

    };

    return (
        <>
            <Header />



            <div className="hero overlay inner-page py-5">
                <div className="container">
                    <div className="row align-items-center justify-content-center text-center pt-5">
                        <div className="col-lg-6">
                            <h1
                                className="heading mb-3 aos-init aos-animate"

                            >
                                Contact Us
                            </h1>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-lg-4 mb-5 mb-lg-0 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={100}
                        >
                            <div className="contact-info">
                                <div className="address mt-2 d-block ">
                                    <i className="icon-room" />
                                    <h4 className="mb-2"> <i class="ri-building-fill"></i> HEAD OFFICE
                                        :</h4>
                                    <p>
                                        Lindon House,
                                        <br />
                                        Heeley Road,
                                        <br />
                                        Birmingham B29 6EN
                                    </p>
                                </div>
                                <hr />
                                <div className="open-hours mt-4">
                                    <i className="icon-clock-o" />
                                    <h4 className="mb-2"><i class="ri-time-fill"></i> Open Hours:</h4>
                                    <p>

                                        9AM - 5:00PM GMT
                                    </p>
                                </div>
                                <hr />
                                <div className="email mt-4">
                                    <i className="icon-envelope" />
                                    <h4 className="mb-2"><i class="ri-mail-open-fill"></i> Email:</h4>
                                    <p>info@brandmenow.co.uk

                                    </p>
                                </div>
                                <hr />
                                <div className="phone mt-4">
                                    <i className="icon-phone" />
                                    <h4 className="mb-2"><i class="ri-phone-fill"></i> Call:</h4>
                                    <p>Phone: 0121 472 5300

                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-8 aos-init aos-animate"
                            data-aos="fade-up"
                            data-aos-delay={200}
                        >
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label className='mb-2'>
                                            Enter Your Name <span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Your Name"
                                            name="name" value={formData.name} onChange={handleChange} required
                                        />
                                    </div>



                                    <div className="col-12 mb-3">
                                        <label className='mb-2'>
                                            Enter Your Email  <span className='text-danger'> *</span>
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder="Your Email" name="email" value={formData.email} onChange={handleChange} required
                                        />
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label className='mb-2'>
                                            Enter Your Message  <span className='text-danger'> *</span>
                                        </label>
                                        <textarea
                                            name="message"
                                            id=""
                                            cols={30}
                                            rows={7}
                                            className="form-control"
                                            placeholder="Message"
                                            defaultValue={""} value={formData.message} onChange={handleChange} required
                                        />
                                    </div>
                                    <ReCAPTCHA
                                        sitekey="6Lfkfr4pAAAAAP6ihPsHTpE--kV3z2Rd5hFhiVf7"
                                        onChange={handleVerification}
                                    />

                                    <div className="col-12 mt-2">
                                        <input
                                            type="submit"
                                            defaultValue="Send Message"
                                            className="btn btn-primary"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='mt-4'>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9729.189867455805!2d-1.9302630000000003!3d52.437526!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bdd36d553169%3A0x5a88d5f44f772017!2sLindon%20House%2C%20Heeley%20Rd%2C%20Selly%20Oak%2C%20Birmingham%20B29%206EN%2C%20UK!5e0!3m2!1sen!2sus!4v1712089020887!5m2!1sen!2sus"
                                width={'100%'}
                                height={450}
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                                referrerPolicy="no-referrer-when-downgrade"
                            />

                        </div>
                    </div>
                </div>
            </div>

            <Footer />

        </>

    )
}

export default Contact