// TokenValidator.js
import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authActions } from '../redux/store';
import toast from 'react-hot-toast';
import axiosInstance from '../api/axiosInstance';

const TokenValidator = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // const validateToken = async () => {
        //     const usertoken = localStorage.getItem('token');
        //     if (usertoken) {
        //         try {
        //             const response = await axiosInstance.get(`http://localhost:8000/validatetoken/${usertoken}`);

        //             if (response.data.success) {
        //                 // Token is valid, dispatch the login action and navigate to the home page
        //                 dispatch(authActions.login());
        //                 //  toast.success("Token found");
        //             } else {
        //                 // Token is invalid, clear local storage token
        //                 localStorage.removeItem('token');
        //                 localStorage.removeItem('userId');
        //                 toast.error("Token expired or invalid");
        //             }
        //         } catch (error) {
        //             // Handle error, for example, show an error message
        //             console.error('Error validating token:', error);
        //             // Optionally, clear local storage token in case of an error
        //             localStorage.removeItem('token');
        //             localStorage.removeItem('userId');
        //             toast.error("Error validating token");
        //         }
        //     }
        // };

        // validateToken();
    }, [dispatch]);

    return null; // This component doesn't render anything visible
};

export default TokenValidator;
