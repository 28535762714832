import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import { useCart } from "react-use-cart";


const Cart = () => {

    const {
        isEmpty,
        totalUniqueItems,
        totalItems,
        items,
        cartTotal,
        updateItemQuantity,
        removeItem,
        emptyCart,
    } = useCart();

    const [showModal, setShowModal] = useState(null); // State to manage which modal is open

    const handleCloseModal = () => setShowModal(null); // Function to close modal

    const handleShowModal = (productId) => setShowModal(productId); // Function to open modal for a specific product


    // if (isEmpty) return <h1 className='text-center'> You cart Is empty</h1>
    return (

        <>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Your Cart ({totalUniqueItems}) Total Items ({totalItems})</h5>

                </div>
                <div className="modal-body">



                    <div className="col-sm-12 col-md-12 col-md-offset-1">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th >Product</th>
                                    <th>Quantity</th>
                                    <th >Price</th>
                                    <th >Total</th>
                                    <th>Action </th>
                                </tr>
                            </thead>
                            <tbody>




                                {items.map((item) => (
                                    <>

                                        <tr key={item.id} >
                                            <td className="col-sm-8 col-md-6" colspan="1">
                                                <div className="media">

                                                    <img src={item.image} width={60} />


                                                    <div className="media-body">

                                                        <h5 className="media-heading">
                                                            {item.title} - {item.color} -
                                                            <br />
                                                            [ {
                                                                Object.entries(item.SelectedSizes).map(([key, value]) => (
                                                                    <><span key={key}>{`${key}: ${value},`}</span>
                                                                    </>
                                                                ))
                                                            } ]
                                                        </h5>
                                                        {item.mappedData.length !== 0 && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={() => handleShowModal(item.id)}
                                                            >
                                                                View Details
                                                            </button>

                                                        )}




                                                        {showModal === item.id && (
                                                            <div className="Custompopup">

                                                                <div
                                                                    id="exampleModalLive"
                                                                    className="modal fade show"
                                                                    tabIndex={-1}
                                                                    role="dialog"
                                                                    aria-labelledby="exampleModalLiveLabel"
                                                                    style={{ display: "block", paddingRight: 17 }}>

                                                                    <div className="modal-dialog" role="document">
                                                                        <div className="modal-content">

                                                                            <div className="modal-body">


                                                                                {item.mappedData.map((data, index) => (
                                                                                    <>
                                                                                        <table key={index} class="table table-striped"
                                                                                        >

                                                                                            <tbody>
                                                                                                <tr>

                                                                                                    <th> Sno.  </th>

                                                                                                    <th>
                                                                                                        {index + 1}
                                                                                                    </th>

                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <th>Print Image : </th>
                                                                                                    <th> {data.image !== '' && data.image ? <img src={data.image} width={100} /> : 'NA'} </th>
                                                                                                </tr>

                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Print Type :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.PrintTyp ||
                                                                                                            'NA'}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Design
                                                                                                        Position :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {
                                                                                                            data.CustomiseTyp ||
                                                                                                            'NA'
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Artwork
                                                                                                        :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.Artwork ||
                                                                                                            'NA'}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Custom
                                                                                                        width :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.width ? (data.width +
                                                                                                            ' CM' ||
                                                                                                            'NA') : 'NA'}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Customisation
                                                                                                        name :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.CustomName ||
                                                                                                            'NA'}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Notes
                                                                                                        or
                                                                                                        special
                                                                                                        instructions
                                                                                                        :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {
                                                                                                            data.CustomNotes ||
                                                                                                            'NA'
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Font
                                                                                                        family
                                                                                                        :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {
                                                                                                            data.CustomFont ||
                                                                                                            'NA'
                                                                                                        }
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Font
                                                                                                        Size
                                                                                                        :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.FontSize !==
                                                                                                            undefined
                                                                                                            ? data.FontSize +
                                                                                                            'px'
                                                                                                            : 'NA'}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Font Color :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.FontColor ||
                                                                                                            'NA'}
                                                                                                    </td>
                                                                                                </tr>


                                                                                                <tr>
                                                                                                    <th>
                                                                                                        Price :
                                                                                                    </th>
                                                                                                    <td>
                                                                                                        {data.price ||
                                                                                                            'NA'}
                                                                                                    </td>
                                                                                                </tr>

                                                                                            </tbody>
                                                                                        </table>
                                                                                        <hr />
                                                                                    </>
                                                                                ))}




                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-danger"
                                                                                    onClick={handleCloseModal}
                                                                                >
                                                                                    Close
                                                                                </button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}




                                                    </div>




                                                </div>
                                            </td>



                                            <td className="col-sm-1 col-md-1" style={{ textAlign: "center" }}>
                                                [ {
                                                    Object.entries(item.SelectedSizes).map(([key, value]) => (
                                                        <><span key={key}>{`${key}: ${value},`}</span>
                                                        </>
                                                    ))
                                                } ]
                                                {/* <div className='d-flex gap-1 align-items-center'>
                                                <button className="btn btn-primary btn-sm" onClick={() => updateItemQuantity(item.id, item.quantity - 1)} >
                                                    -
                                                </button>
                                                <span>
                                                    {item.quantity}
                                                </span>
                                                <button className="btn btn-primary btn-sm" onClick={() => updateItemQuantity(item.id, item.quantity + 1)} >
                                                    +
                                                </button>
                                            </div> */}

                                                <hr />
                                                <img src={item.overlayImage} width={'100px'} />


                                            </td>
                                            <td className="col-sm-1 col-md-1">
                                                <strong>£{item.price}</strong>
                                            </td>
                                            <td className="col-sm-1 col-md-1">
                                                <strong>£{item.quantity * item.price}</strong>
                                            </td>
                                            <td className="col-sm-1 col-md-1">
                                                <button type="button" className="btn btn-danger" onClick={() => removeItem(item.id)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width={20}
                                                        height={20}
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#ffffff"
                                                        strokeWidth={2}
                                                        strokeLinecap="square"
                                                        strokeLinejoin="arcs"
                                                        style={{ margin: 0 }}

                                                    >
                                                        <polyline points="3 6 5 6 21 6" />
                                                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" />
                                                        <line x1={10} y1={11} x2={10} y2={17} />
                                                        <line x1={14} y1={11} x2={14} y2={17} />
                                                    </svg>

                                                </button>
                                            </td>
                                        </tr>



                                    </>

                                ))}

                                {!isEmpty &&

                                    <tr>
                                        <td colSpan={4}>
                                            Total Print Customisation
                                        </td>
                                        <td className="fw-bold">
                                            £  {items
                                                .flatMap(item => item.mappedData.map(data => data.customtax)) // Extract all customtax values
                                                .reduce((acc, curr) => acc + curr, 0) // Sum all values
                                            }
                                        </td>

                                    </tr>

                                }


                                {isEmpty ? '' : (
                                    <tr>
                                        <td></td>
                                        <td></td> <td></td>
                                        <td>
                                            <h5>Total</h5>
                                        </td>
                                        <td className="text-right">
                                            <h5>
                                                <strong>£{cartTotal + items
                                                    .flatMap(item => item.mappedData.map(data => data.customtax)) // Extract all customtax values
                                                    .reduce((acc, curr) => acc + curr, 0)}</strong>
                                            </h5>
                                        </td>
                                    </tr>)}






                            </tbody>
                        </table>

                        <div class="text-center w-100">{isEmpty ? 'You cart Is empty' : ''}
                        </div>

                    </div>

                </div>



            </div >



            <div className="modal-footer">

                <Link to="/" className="btn btn-primary m-auto">
                    <span className="fa fa-shopping-cart" /> Continue Shopping
                </Link>

                <Link to="/checkout" className="btn btn-success m-auto">
                    Checkout
                </Link>
            </div>

        </>

    )
}

export default Cart