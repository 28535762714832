import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useLocation } from 'react-router-dom';
import { useCart } from "react-use-cart";
import axiosInstance from '../../api/axiosInstance';
const Success = () => {

    const { items, emptyCart } = useCart();


    const [paymentInfo, setPaymentInfo] = useState(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const session_id = searchParams.get('session_id');

    useEffect(() => {
        const fetchPaymentInfo = async () => {
            try {
                const response = await axiosInstance.get(`/success?session_id=${session_id}`);
                setPaymentInfo(response.data);
                emptyCart();

            } catch (error) {
                console.error('Error fetching payment info:', error);
                toast.error('Failed to fetch payment info');
            }
        };

        if (session_id) {
            fetchPaymentInfo();
        }
    }, [session_id]);

    return (
        <>
            <Header />
            <div>

                {paymentInfo ? (
                    <div className="text-center container mt-5 mb-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="none" stroke="#01b926" stroke-width="2" stroke-linecap="square" stroke-linejoin="arcs"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>

                        <h1>Payment Successful!</h1>
                        <p>Name: {paymentInfo.name}</p>
                        <p>Amount: ${paymentInfo.amount}</p>
                        <p>Transaction ID: {paymentInfo.transactionID}</p>
                        <Link class="btn btn-primary m-auto" to="/account/orders"><span class="fa fa-shopping-cart"></span> My orders </Link>
                    </div>
                ) : (
                    <div className="text-center container mt-5 mb-5" >
                        <i className="fa fa-spinner fa-spin" style={{ fontSize: 90 }} />

                        <br />    <br />
                        Loading payment information...</div>
                )}
            </div>
            <Footer />
        </>
    );
};

export default Success;
